import { MANUAL_INPUT_TYPE, RENDER_TYPES } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';
import SelectDropdownInput from './SelectDropdownInput';
import SelectChipInput from './SelectChipInput';
import StrengthInput from './StrenghtInput';
import SelectIconInput from './SelectIconInput';

const COMPONENT_MAPPING = {
  severity: SelectChipInput,
  strength: StrengthInput,
};

export function SelectInput({ options, as, labelProps, ...props }) {
  switch (props?.renderType) {
    case RENDER_TYPES.CHIP:
      return (
        <SelectChipInput options={options} labelProps={labelProps} {...props} />
      );
    case RENDER_TYPES.ICON:
      return <SelectIconInput options={options} {...props} />;
    default:
      const Component = COMPONENT_MAPPING[props.name] || SelectDropdownInput;
      return <Component options={options} {...props} />;
  }
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.SELECT, SelectInput);
