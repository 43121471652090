import { split, last, head } from 'ramda';

export function getAttachmentFileName(key) {
  return key && last(split('/', key));
}

export function getAttachmentFileNameWithoutExtension(key = '') {
  const parts = getAttachmentFileName(key)?.split?.('.');
  parts?.pop?.();
  return parts.join('.');
}

export function getAttachmentExtension(key) {
  return key && last(split('.', key));
}

export function getAttachmentType(type) {
  return type && head(split('/', type));
}
