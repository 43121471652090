import { useStoryBlockQuery } from 'hooks/useStoryblockQuery';

function useStoryBlock(props, prefix) {
  const watchLibrary = props.watch(`${prefix}.name`, {
    value: '',
    suggestion: null,
  });

  const storyBlockId =
    watchLibrary?.suggestion?.id ||
    props.getValues()?.[`${prefix}.name`]?.suggestion?.id;

  const { data } = useStoryBlockQuery(storyBlockId, {
    enabled: Boolean(storyBlockId),
  });

  const topic = data?.linkages?.topics?.[0];
  const description = data?.description ?? topic?.description;
  const synonyms = data?.synonyms;
  const image = data?.thumbnail;

  return { data, topic, description, synonyms, image };
}

export default useStoryBlock;
