import classnames from 'classnames';
import { identity, times } from 'ramda';

function Step({ label, isActive }) {
  const classes = classnames(
    'flex h-10 w-10 items-center shrink-0 justify-center rounded-full border-1 text-smd-base font-bold text-white z-10',
    {
      'bg-smd-accent text-white': isActive,
      'border-1 border-smd-gray-dark text-black bg-white': !isActive,
    }
  );
  return (
    <div className={classes}>
      <span>{label}</span>
    </div>
  );
}

export default function Steps({ total, activeStep, className }) {
  const steps = times(identity, total);
  return (
    <div
      className={classnames(
        'relative isolate inline-flex w-full items-center justify-between overflow-x-auto before:absolute before:block before:h-[1px] before:w-full before:bg-smd-accent',
        className
      )}
    >
      {steps.map((index) => (
        <Step
          key={`step-${index}`}
          label={index + 1}
          isActive={index + 1 === activeStep}
        />
      ))}
    </div>
  );
}
