import { useMemo } from 'react';
import { usePhrUnitValuePreference } from 'my-phr/hooks';
import { getRangesResultStatusColor } from 'my-phr/components/result/utils';

export default function useChartDataWithFeedbackColor(data, topic) {
  const { getValue, getRawUnit } = usePhrUnitValuePreference();
  return useMemo(
    () =>
      data.map((x) => {
        // getting the range color for the value for each point in the chart
        const color = getRangesResultStatusColor({
          topics: topic ? [topic] : [],
          rawUnit: getRawUnit(x),
          value: getValue(x),
          molecularWeight: x?.storyblock?.molecularWeight,
        });

        return {
          ...x,
          effectiveTs: new Date(x.effectiveTs).getTime(),
          value: x.value,
          topics: topic ? [topic] : [],
          color,
        };
      }),
    [data, topic, getValue, getRawUnit]
  );
}
