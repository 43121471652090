import { Link, useLocation } from 'react-router-dom';
import keycloak from 'keycloak';

import { useIsFeatureEnabled } from 'hooks';
import { Button } from 'components/forms';
import { PHR_ROUTES } from 'my-phr/const';

function CTA({
  ctaProps,
  redirectUri,
  linkClassName = 'rounded smd-focus-visible-primary py-2',
  toHealth = false,
  ...rest
}) {
  const location = useLocation();
  const isSignUpEnabled = useIsFeatureEnabled(useIsFeatureEnabled.SIGNUP);

  const { ctaAction, ctaInternal, ctaExternal } = ctaProps ?? {};

  const fromHealth = location.pathname.startsWith('/health') || toHealth;

  let redirectLink = `${window.location.origin}${PHR_ROUTES.ACTIVATE}`;

  if (fromHealth) {
    redirectLink = redirectLink?.concat?.('?smd-health=true');
  }

  function redirectToLoginPage(e) {
    const keycloakUrl = {
      register: keycloak.createRegisterUrl({
        redirectUri: redirectLink,
      }),
      login: keycloak.createLoginUrl({
        redirectUri: redirectLink,
      }),
    };
    window.location = keycloakUrl[ctaAction];
  }
  if (ctaAction) {
    return isSignUpEnabled ? (
      <Button
        role="link"
        onClick={redirectToLoginPage}
        className={linkClassName}
        {...rest}
      />
    ) : null;
  }

  if (ctaInternal) {
    return (
      <Link to={ctaInternal} className={linkClassName}>
        <Button {...rest} tabIndex={-1} />
      </Link>
    );
  }
  if (ctaExternal) {
    return (
      <a
        href={ctaExternal}
        target="_blank"
        rel="noopener noreferrer"
        className={linkClassName}
      >
        <Button {...rest} tabIndex={-1} />
      </a>
    );
  }
  return <Button {...rest} />;
}

export default CTA;
