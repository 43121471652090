import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AutoSuggest from 'react-autosuggest';
import { path, prop } from 'ramda';

import { useSearchSuggestions } from 'hooks';
import { mergeRefs } from 'utils';

import {
  DEFAULT_STORYBLOCK_TYPES,
  MANUAL_INPUT_CATEGORY,
} from 'my-phr/layout/modules/ManualInputs/const';
import { Search, X } from '../icons';

import theme from './SearchSuggestions.module.css';
import SuggestionRecord from './SuggestionRecord';
import Spinner from './Spinner';
import SuggestionsContainer from './SuggestionContainer';
import HighlightedSuggestionRecord from './HighlightedSuggestionRecord';
import { isMeasurement } from './utils';

const INPUT_MAX_LENGTH = 200;

const SearchSuggestions = forwardRef(
  (
    {
      value,
      onChange,
      onSearch,
      placeholder,
      debounce,
      loading,
      hideSuggestions = false,
      className,
      wrapperClassName,
      btnClassName,
      autoFocus,
      renderSuggestionsContainer,
      testId,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const searchSuggestRef = useRef();

    const { suggestions, fetch, clear } = useSearchSuggestions(
      debounce,
      null,
      {},
      {
        enabled: value?.length > 0,
      }
    );

    const inputProps = {
      value,
      placeholder,
      onChange: (e, { newValue, method }) => {
        if (['escape', 'enter', 'click', 'type'].includes(method)) {
          onChange(newValue);
        }
      },
    };

    const overriddenTheme = useMemo(
      () => ({ ...theme, container: classnames(theme.container, className) }),
      [className]
    );

    useEffect(() => {
      if (autoFocus) {
        searchSuggestRef.current?.input?.focus();
      }
    }, [autoFocus]);

    return (
      <AutoSuggest
        theme={overriddenTheme}
        inputProps={inputProps}
        suggestions={suggestions}
        getSuggestionValue={prop('name')}
        onSuggestionsFetchRequested={fetch}
        onSuggestionsClearRequested={clear}
        onSuggestionSelected={(e, { suggestionValue }) =>
          onSearch(suggestionValue)
        }
        shouldRenderSuggestions={() => !hideSuggestions}
        renderSuggestion={(suggestion, { query }) => (
          <SuggestionRecord suggestion={suggestion} query={query} />
        )}
        renderInputComponent={(inputProps) => (
          <form
            data-testid="suggestionsInputWrapper"
            className={classnames(
              'flex-center flex-nowrap bg-white',
              'smd-input-focus-within-primary border border-smd',
              'overflow-hidden rounded-sm',
              'antialiased',
              wrapperClassName
            )}
            onSubmit={(event) => {
              event.preventDefault();
              searchSuggestRef.current?.input?.blur();
              onSearch(value);
            }}
          >
            <input
              {...inputProps}
              className={classnames(
                'h-full w-full px-3',
                'truncate indent-0.5 italic text-smd-main',
                'placeholder:italic placeholder:text-smd-gray-dark',
                'border-none ring-0 focus:ring-0',
                'bg-transparent'
              )}
              data-testid={testId}
            />
            {loading && <Spinner className="mr-1 h-6 w-6 p-1 text-smd-gray" />}
            {inputProps.value?.length > 0 && (
              <button
                type="button"
                className={classnames(
                  'mr-3 rounded',
                  'text-smd-gray',
                  'smd-input-focus-primary',
                  'select-none'
                )}
                onClick={() => {
                  onChange('');
                }}
                aria-label={t('labels.common.clear')}
              >
                <X className="h-6 w-6 stroke-2" />
              </button>
            )}
            <button
              data-testid="suggestionsSubmitBtn"
              type="submit"
              className={classnames(
                'h-full px-3',
                'smd-input-focus-secondary ring-inset ring-smd-accent-bright',
                'select-none',
                btnClassName ??
                  'bg-smd-accent text-white hover:bg-smd-accent-dark'
              )}
              aria-label={
                inputProps.value?.length > 0
                  ? t('labels.common.search-button-value', {
                      value: inputProps.value,
                    })
                  : t('labels.common.search-button-empty')
              }
            >
              <Search className="h-5 w-5 stroke-2" />
            </button>
          </form>
        )}
        renderSuggestionsContainer={renderSuggestionsContainer}
        ref={mergeRefs([searchSuggestRef, ref])}
      />
    );
  }
);

SearchSuggestions.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  debounce: PropTypes.number,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};

SearchSuggestions.defaultProps = {
  value: '',
  placeholder: '',
  debounce: 0,
  loading: false,
  onChange: () => {},
  onSearch: () => {},
};

function SearchSuggestionsUncontrolled({ value, onChange, ...props }) {
  const [_value, _setValue] = useState(value ?? '');

  useEffect(() => {
    _setValue(value ?? '');
  }, [value]);

  const handleChange = useCallback(
    (value) => {
      _setValue(value);
      onChange?.(value);
    },
    [onChange]
  );

  return (
    <SearchSuggestions value={_value} onChange={handleChange} {...props} />
  );
}

const SearchSuggestionsSimple = forwardRef(
  (
    {
      id,
      suggestions,
      onFetch,
      onClear,
      value,
      onChange,
      placeholder,
      hideSuggestions = false,
      className,
      wrapperClassName,
      inputClassName,
      renderSuggestionsContainer,
      inputRef,
      customContainerStyle,
      onAddNew = () => {},
      onForeignCategoryItemSelect,
      onCategoryChange = () => {},
      getInputValue = () => {},
      defaultSuggestion,
      inEditMode = false,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [containerClassName, setContainerClassName] = useState(
      theme.customContainer
    );

    const inputProps = {
      value,
      placeholder,
      onChange: (e, { newValue, method }) => {
        if (['escape', 'enter', 'click', 'type'].includes(method)) {
          onChange({ value: newValue, suggestion: null });
          setContainerClassName(theme.customContainer);
        }
      },
      onKeyDown: (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onChange({
            value: e.target.value,
            suggestion: defaultSuggestion,
          });
          setContainerClassName(theme.container);
          e.target.blur();
        }
      },
    };

    useEffect(() => {
      if (!inputProps.value) {
        setContainerClassName(theme.customContainer);
      }
    }, [inputProps.value]);

    const overriddenTheme = useMemo(
      () => ({
        ...theme,
        container: classnames(
          customContainerStyle ? containerClassName : theme.container,
          className
        ),
        suggestionsList: classnames(
          customContainerStyle
            ? theme.customSuggestionsList
            : theme.suggestionsList
        ),
      }),
      [className, customContainerStyle, containerClassName]
    );

    function onSuggestionSelected(
      event,
      { suggestionValue, suggestion, method }
    ) {
      if (suggestion?.isOtherCategory) {
        const resolvedAutocompleteType = Object.keys(
          DEFAULT_STORYBLOCK_TYPES
        ).find((key) => DEFAULT_STORYBLOCK_TYPES[key] === suggestion?.type);

        const autocompleteType = isMeasurement(suggestion)
          ? MANUAL_INPUT_CATEGORY.MEASUREMENT
          : resolvedAutocompleteType;

        onForeignCategoryItemSelect(autocompleteType, suggestion);
        onCategoryChange(autocompleteType, suggestion?.category?.[0] ?? '');
        getInputValue(suggestionValue, autocompleteType);
        return;
      }
      const isEnterKeyMethod = method === 'enter';
      setContainerClassName(theme.container);
      if (suggestion?.addsNew) {
        const userInput = isEnterKeyMethod
          ? path(['target', 'value'], event)
          : event?.target?.getAttribute?.('data-value');
        onChange({
          value: userInput,
          suggestion: {
            ...suggestion,
            name: userInput,
          },
        });
        getInputValue(userInput);
        onAddNew();
      } else {
        onChange({
          value: suggestionValue,
          suggestion,
        });
        getInputValue(suggestionValue);
        if (isEnterKeyMethod) {
          event.target.blur();
        }
      }
    }

    return (
      <AutoSuggest
        theme={overriddenTheme}
        inputProps={inputProps}
        suggestions={suggestions}
        getSuggestionValue={prop('name')}
        onSuggestionsFetchRequested={(x) => onFetch?.(x)}
        onSuggestionsClearRequested={onClear}
        onSuggestionSelected={onSuggestionSelected}
        shouldRenderSuggestions={() => !hideSuggestions}
        renderSuggestion={(suggestion, { query }) => {
          return (
            <HighlightedSuggestionRecord
              suggestion={suggestion}
              query={query}
            />
          );
        }}
        focusInputOnSuggestionClick={false}
        renderInputComponent={(inputProps) => (
          <div
            className={classnames(
              'relative flex items-center',
              wrapperClassName
            )}
          >
            <input
              {...inputProps}
              id={id}
              className={classnames(
                'grow p-2 pr-20 leading-normal',
                'smd-input-focus-primary border-smd-gray-light',
                'placeholder:text-smd-tertiary',
                inputClassName
              )}
              disabled={inEditMode}
              maxLength={INPUT_MAX_LENGTH}
            />
            {inputProps.value?.length > 0 && (
              <button
                disabled={inEditMode}
                type="button"
                className={classnames(
                  'absolute right-10 rounded',
                  'smd-input-focus-primary',
                  'select-none'
                )}
                onClick={() => {
                  onChange({ value: '', suggestion: null });
                  setContainerClassName(theme.customContainer);
                }}
                aria-label={t('labels.common.clear')}
              >
                <X className="h-6 w-6 stroke-2" />
              </button>
            )}
            <div className="pointer-events-none absolute right-0 px-2">
              <Search className="h-5 w-5 stroke-2" />
            </div>
          </div>
        )}
        renderSuggestionsContainer={renderSuggestionsContainer}
        ref={(el) => {
          inputRef?.(el?.input);
          ref?.(el);
        }}
      />
    );
  }
);

SearchSuggestionsSimple.defaultProps = {
  value: '',
  placeholder: '',
  inputRef: () => {},
  suggestions: [],
  onFetch: () => {},
  onClear: () => {},
  onChange: () => {},
};

SearchSuggestions.Uncontrolled = SearchSuggestionsUncontrolled;
SearchSuggestions.SuggestionContainer = SuggestionsContainer;
SearchSuggestions.Simple = SearchSuggestionsSimple;

export default SearchSuggestions;
