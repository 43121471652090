import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import classnames from 'classnames';

import { capitalizeFirstLetter } from 'utils';
import { Tooltip } from 'components';
import { Button, SquareButton } from 'components/forms';
import { StoryMDInfo, X } from 'components/icons';

import { MANUAL_INPUT_CONFIG_TYPE, MANUAL_INPUT_TYPE } from '../const';
import manualInputsRegistry from '../manualInputsRegistry';
import { getManualInput, removeDefaultStoryBlockSuffix } from '../utils';

export function LibraryInput({
  data,
  isGrouped = false,
  isManualEntry = true,
  namePrefix,
  ...props
}) {
  const configType = data.type;

  const inputTypes = [
    MANUAL_INPUT_CONFIG_TYPE.OBSERVATION,
    MANUAL_INPUT_CONFIG_TYPE.ALLERGY_INTOLERANCE,
    MANUAL_INPUT_CONFIG_TYPE.CONDITION,
    MANUAL_INPUT_CONFIG_TYPE.MEDICATION,
    MANUAL_INPUT_CONFIG_TYPE.PROCEDURE,
    MANUAL_INPUT_CONFIG_TYPE.VACCINE,
    MANUAL_INPUT_CONFIG_TYPE.NOTE,
  ];

  if (inputTypes?.includes?.(removeDefaultStoryBlockSuffix?.(configType))) {
    const grouped = isGrouped && Boolean(data.label);
    return (
      <fieldset
        className={classnames(
          'flex w-full flex-col flex-wrap gap-x-2 gap-y-3',
          grouped && ''
        )}
      >
        {/* TODO: add molecular weight to body sent to back-end */}
        {grouped && (
          <legend className="absolute top-0 left-0 flex -translate-y-1/2 -translate-x-px items-center gap-0.5 text-sm leading-none text-smd-accent before:h-px before:w-1.5 before:bg-smd-accent">
            {data.label}
          </legend>
        )}
        {Boolean(data?.chartType) &&
          getManualInput(MANUAL_INPUT_TYPE.HIDDEN, {
            ...props,
            value: data.chartType,
            name: namePrefix ? `${namePrefix}.chartType` : `chartType`,
          })}
        {Boolean(data?.molecularWeight) &&
          getManualInput(MANUAL_INPUT_TYPE.HIDDEN, {
            ...props,
            value: data?.molecularWeight,
            name: namePrefix
              ? `${namePrefix}.molecularWeight`
              : `molecularWeight`,
          })}
        {data?.fields?.map(({ inputType, ...field }, index) => {
          const isGroupedManualEntry = isGrouped && isManualEntry;
          return inputType === MANUAL_INPUT_TYPE.PANEL.toLocaleLowerCase() ? (
            <LabPanelFieldArray data={data} {...props} />
          ) : (
            getManualInput(inputType.toUpperCase(), {
              ...props,
              ...field,
              groupClassName: classnames('flex-1'),
              labelProps: isGroupedManualEntry
                ? { className: 'text-smd-gray-dark text-xs pb-2' }
                : null,
              key: `${field?.name}.${index}`,
              name: namePrefix ? `${namePrefix}${field?.name}` : field?.name,
              namePrefix: namePrefix,
              label: isGroupedManualEntry
                ? data?.synonyms?.[0]
                : field?.label ?? capitalizeFirstLetter(field?.name),
              ranges: data?.ranges,
              observations: data?.components,
            })
          );
        })}
      </fieldset>
    );
  }

  if (configType === MANUAL_INPUT_CONFIG_TYPE.OBSERVATION_PANEL) {
    return <LabPanelFieldArray data={data} isManualEntry={false} {...props} />;
  }

  return null;
}

export function LabPanelFieldArray({ isManualEntry, data, ...props }) {
  const { t } = useTranslation();
  const { fields: observations, remove } = useFieldArray({
    control: props.control,
    name: `${props.prefix}.observations`,
  });

  const observationFields = data?.observations ?? data?.fields?.[0].components;
  const rules = data?.fields?.[0]?.rules;

  useEffect(() => {
    if (observationFields?.length) {
      props.setValue(`${props.prefix}.observations`, observationFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setValue, props.prefix, observationFields]);

  const [observationsState, setObservationsState] = useState(
    observationFields?.map(() => ({ showConfirmation: false }))
  );

  return (
    <div className="pb-10">
      {observations?.map(({ id, ...observation }, index) => {
        const componentItem = observationFields[index];

        const isBeingDeleted = observationsState[index]?.showConfirmation;
        const updatedObservationsState = [...observationsState];

        return (
          <div
            className="mt-4 flex grow flex-col border border-smd-gray-lighter p-2"
            key={id}
          >
            {isBeingDeleted ? (
              <div className="space-y-4">
                <div className="text-smd-sm font-bold">
                  {t('my-phr.ocr.remove-test')}
                </div>

                <div className="flex justify-end space-x-4">
                  <Button.Tertiary
                    onClick={() => {
                      updatedObservationsState[index].showConfirmation = false;
                      setObservationsState(updatedObservationsState);
                    }}
                  >
                    {t('common.cancel')}
                  </Button.Tertiary>

                  <Button.Alert
                    onClick={() => {
                      remove(index);
                      updatedObservationsState[index].showConfirmation = false;
                      setObservationsState(updatedObservationsState);
                    }}
                  >
                    {t('common.remove')}
                  </Button.Alert>
                </div>
              </div>
            ) : (
              <>
                <div className="flex justify-between pr-2">
                  <div className="flex space-x-2">
                    <div className="font-bold">{observation?.name}</div>
                    {observation?.description && (
                      <span>
                        <Tooltip
                          content={
                            <span
                              className="overflow-hidden break-words"
                              dangerouslySetInnerHTML={{
                                __html: observation?.description,
                              }}
                            />
                          }
                        >
                          {(props) => (
                            <StoryMDInfo {...props} className="h-6 w-6" />
                          )}
                        </Tooltip>
                      </span>
                    )}
                  </div>
                  {!rules?.forceSelection ? (
                    <RemoveButton
                      onClick={() => {
                        updatedObservationsState[index].showConfirmation = true;
                        setObservationsState(updatedObservationsState);
                      }}
                    />
                  ) : null}
                </div>
                <div className="flex gap-2">
                  <LibraryInput
                    {...props}
                    key={id}
                    data={{
                      ...observation,
                      id: componentItem?.id,
                    }}
                    isGrouped
                    isManualEntry={isManualEntry}
                    namePrefix={`observations.${index}.`}
                  />
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

function RemoveButton({ onClick, className }) {
  const { t } = useTranslation();
  return (
    <SquareButton
      onClick={onClick}
      aria-label={t('labels.common.close')}
      className={className}
    >
      <X strokeWidth="3" />
    </SquareButton>
  );
}

manualInputsRegistry.register(MANUAL_INPUT_TYPE.LIBRARY, LibraryInput);
