import { Fragment, lazy, Suspense, useEffect } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { ErrorBoundary, withProfiler } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useLocalStorage } from 'react-use';
import { Toaster } from 'react-hot-toast';
import { install as installResizeObserver } from 'resize-observer';
import classnames from 'classnames';

import { applyDistributionColorPalette } from 'distribution';

import { LoadingComponent, Menu } from 'ui';

import Error from 'Error';

import ProfileContext, { useProfile } from 'ProfileContext';
import { PartnerProvider } from 'PartnerContext';
import { OCRProvider } from 'OCRContext';
import Auth, { useAuth, Roles, forceUpdateToken } from 'Auth';
import OldBrowserWarning from 'OldBrowserWarning';
import NotFound from 'NotFound';
import { Footer } from 'components';
import { SEOMeta } from 'components/SEO';
import { CookiesPrompt } from 'components/Cookies';
import { ScrollProvider } from 'components/Scroll';
import {
  useInitCdn,
  useIsFeatureEnabled,
  usePageView,
  useParseLandingPageData,
  usePublicResource,
  useUserProfile,
  useInvalidateUserProfile,
  useMedia,
} from 'hooks';

import { viewerPath } from 'journals/viewer/const';
import { EmbedJournalsViewer } from 'journals/viewer/containers';
import { journalsPath } from 'journals/const';
import { ChannelsPublicApiProvider } from 'journals/channels';
import { JournalsPublicApiProvider } from 'journals/context';

import { LibraryPublicApiProvider } from 'library';
import {
  LibraryStoryViewerRoutedPage,
  LibraryTopicViewerRoutedPage,
} from 'library/pages';
import { libraryPath } from 'library/const';

import {
  ContactPage,
  DocumentPage,
  PublicCommunities,
  PublicJournals,
} from 'landing/pages';
import { Journey, Journeys } from 'journals/journeys';
import { POCView } from 'journals/poc';
import { DEFAULT_CONTEXT } from 'landing/const';
import { SettingsProvider } from 'SettingsContext';
import { toasterOptions } from 'ui';
import { UnblurModal as UnblurSensitiveContentConfirmationModal } from 'components/BlurredLayer';
import FEATURES from './features';
import Portal from './components/Portal';
import LibraryAssetViewer from './library/components/asset/LibraryAssetViewer';
import { useBackgroud } from './library/hooks';

const Explore = lazy(() => import('search'));
const GlobalLibrarySearch = lazy(() => import('search/GlobalLibrarySearch'));
const CompleteProfile = lazy(() => import('account/CompleteProfile'));

const RouteJournalViewer = lazy(() =>
  import('journals/viewer/containers/RouteJournalViewer')
);
const POCRouteJournalViewer = lazy(() =>
  import('journals/viewer/containers/POCRouteJournalViewer')
);

// Auth pages
const Account = lazy(() => import('account'));
const Admin = lazy(() => import('admin'));
const Communities = lazy(() => import('communities'));
const Editor = lazy(() => import('editor'));
const Journals = lazy(() => import('journals'));
const ProAccount = lazy(() => import('pro-account'));

// Lazy loading heavier landing pages, others are imported as usual
const HelpPage = lazy(() => import('landing/pages/Help'));
const LandingPage = lazy(() => import('landing/pages/Landing'));
const POCLandingPage = lazy(() => import('landing/pages/POCLanding'));
const WelcomePage = lazy(() => import('landing/pages/WelcomeStory'));
const MyPhr = lazy(() => import('my-phr'));

applyDistributionColorPalette();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

function DefaultMeta() {
  const { t } = useTranslation();
  return (
    <SEOMeta
      values={{
        description: t('meta.description'),
        keywords: t('meta.keywords'),
      }}
    />
  );
}

function PublicApiProvider({ children }) {
  return (
    <LibraryPublicApiProvider>
      <ChannelsPublicApiProvider>
        <JournalsPublicApiProvider>{children}</JournalsPublicApiProvider>
      </ChannelsPublicApiProvider>
    </LibraryPublicApiProvider>
  );
}

if (!window.ResizeObserver) {
  installResizeObserver(); // For Safari below 13.1
}

function App() {
  usePageView();
  let location = useLocation();
  const [profile, setProfile] = useProfile();
  const { hasRole } = useAuth();
  const isHealthProfessional = hasRole(Roles.HP);

  const isCommunitiesEnabled = useIsFeatureEnabled(
    useIsFeatureEnabled.COMMUNITIES
  );
  const isMyLibraryEnabled = useIsFeatureEnabled(useIsFeatureEnabled.MYLIBRARY);

  const { context } = useParams();
  const contentContext = context ?? DEFAULT_CONTEXT;
  const { data, isFetched: isLandingPageDataFetched } =
    usePublicResource('/landing-page.json');
  const parsedLandingData = useParseLandingPageData(data, contentContext);

  const { authenticated, initialized } = useAuth();

  const isLargeScreen = useMedia(useMedia.LARGE);

  const { isInitializing } = useInitCdn(authenticated);

  const background = useBackgroud();
  const invalidateUserProfile = useInvalidateUserProfile();

  const [, , removeFilters] = useLocalStorage('timeline-filters');

  // REMOVE timeline-filters from LocalStorage. This will be used until merged with 3205
  useEffect(() => {
    if (!authenticated) removeFilters();
  }, [authenticated, removeFilters]);

  useUserProfile({
    enabled: authenticated,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess: (res) => setProfile(res),
  });

  useEffect(() => {
    if (!authenticated) setProfile(null);
  }, [authenticated, setProfile]);

  const showMenu = !useRouteMatch([`/${viewerPath.EMBED}`]);

  const showShrunkHeader = useRouteMatch([
    `/${viewerPath.JOURNAL}`,
    `/${viewerPath.POC_JOURNAL}`,
    `/${libraryPath.TOPIC}`,
  ]);

  const routesWithoutFooter = !useRouteMatch([
    `/${viewerPath.JOURNAL}`,
    `/${viewerPath.EMBED}`,
    `/${viewerPath.POC_JOURNAL}`,
    `/${libraryPath.TOPIC}`,
    `/${libraryPath.STORY}`,
    `/${libraryPath.ASSET}`,
    '/account',
    '/admin',
    '/editor',
    '/health/*',
    '/journeys',
  ]);

  const routesWithFooterOnLargeScreen =
    useRouteMatch(['/journeys/']) && isLargeScreen;

  const showFooter = routesWithoutFooter || routesWithFooterOnLargeScreen;

  if (!initialized || isInitializing) {
    return <LoadingComponent />;
  }

  if (profile && !profile.completed) {
    return (
      <Suspense fallback={<LoadingComponent />}>
        <CompleteProfile
          onSubmit={() => {
            forceUpdateToken();
            invalidateUserProfile();
          }}
        />
      </Suspense>
    );
  }

  const Wrapper = authenticated ? Fragment : PublicApiProvider;
  const JournalsPage = authenticated ? Journals : PublicJournals;
  const CommunitiesPage =
    isCommunitiesEnabled && (authenticated ? Communities : PublicCommunities);

  return (
    <Wrapper>
      <DefaultMeta />
      <ScrollProvider
        disableResetScrollOnNavigate={background}
        disableOnScroll={showShrunkHeader}
      >
        <div className="flex h-screen flex-col">
          {showMenu && (
            <Menu
              profile={profile}
              admin={hasRole(Roles.ADMIN)}
              context={context}
            />
          )}
          <div id="app-content" />
          <Toaster {...toasterOptions} />
          <div
            className={classnames(
              'relative flex grow flex-col pt-14',
              !showShrunkHeader && showMenu && 'lg:pt-28'
            )}
          >
            <Suspense fallback={<div className="grow" />}>
              <Switch location={background || location}>
                {/* ASSET BACKGROUND PAGES */}

                <Route exact path="/explore" component={Explore} />
                <Route
                  path="/search/:resourceType"
                  component={GlobalLibrarySearch}
                />
                <Route
                  path={`/${libraryPath.STORY}/:hashId`}
                  component={LibraryStoryViewerRoutedPage}
                />
                <Route
                  path={`/${libraryPath.TOPIC}/:hashId`}
                  component={LibraryTopicViewerRoutedPage}
                />
                {location.pathname === '/channels' &&
                  location.search.includes('tag') && (
                    <Redirect to={`/journeys`} />
                  )}
                <Redirect
                  from={`/${viewerPath.EMBED}/channels/:id`}
                  to={`/journeys`}
                />
                <Redirect
                  from={`/${viewerPath.EMBED}/collections/:id`}
                  to={`/journeys`}
                />
                <Route
                  path={`/${viewerPath.EMBED}/:journalKey`}
                  component={EmbedJournalsViewer}
                />
                <Route
                  path={`/${viewerPath.JOURNAL}/:journalKey`}
                  render={() => (
                    <RouteJournalViewer
                      collectionAllocation={authenticated}
                      isPublic={!authenticated}
                    />
                  )}
                />
                <Route
                  path={`/${viewerPath.POC_JOURNAL}/:journalLinkId`}
                  render={() => (
                    <POCRouteJournalViewer
                      collectionAllocation={authenticated}
                      isPublic={!authenticated}
                    />
                  )}
                />

                {/* END ASSET BACKGROUND PAGES */}

                <Route
                  exact
                  path={`/${libraryPath.ASSET}/404`}
                  component={NotFound}
                />

                <Route
                  path="*"
                  render={() => (
                    <>
                      <Suspense
                        fallback={<div className="min-h-screen grow" />}
                      >
                        <Switch>
                          {/* PUBLIC PAGES */}
                          <Route exact path="/" component={WelcomePage} />
                          <Route
                            exact
                            path="/context/:context"
                            render={() => (
                              <LandingPage
                                parsedData={parsedLandingData}
                                isFetched={isLandingPageDataFetched}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/point-of-care"
                            component={POCLandingPage}
                          />
                          <Route path="/help-center" component={HelpPage} />
                          <Route
                            exact
                            path="/contact-us"
                            component={ContactPage}
                          />
                          <Route
                            exact
                            path={[
                              '/privacy-policy',
                              '/accessibility',
                              '/terms',
                            ]}
                            component={DocumentPage}
                          />
                          {/* END PUBLIC PAGES */}

                          {/* EXACT PAGES */}
                          <Route
                            exact
                            path={`/${libraryPath.ASSET}/:hashId`}
                            component={LibraryAssetViewer.Page}
                          />

                          <Route
                            exact
                            path={`/${viewerPath.EMBED404}`}
                            component={NotFound}
                          />

                          {/* END EXACT PAGES */}

                          {isCommunitiesEnabled && (
                            <Route
                              path="/communities"
                              component={CommunitiesPage}
                            />
                          )}
                          {isHealthProfessional && (
                            <Route path="/poc" component={POCView} />
                          )}
                          <Redirect from="/channels" to="/journeys" />
                          <Redirect from="/collections" to="/journeys" />
                          <Redirect from="/initiatives" to="/journeys" />

                          <Route
                            exact
                            path="/journey/:journeyId/:collectionId?/:subCollectionId?"
                            component={Journey}
                          />

                          <Route path="/journeys" component={Journeys} />
                          {isMyLibraryEnabled && (
                            <Route
                              path={`/${journalsPath.JOURNALS}`}
                              component={JournalsPage}
                            />
                          )}

                          <Route
                            path={[
                              '/editor/:draftId',
                              '/account',
                              '/admin',
                              '/pro-account',
                            ]}
                            render={() =>
                              authenticated ? (
                                <>
                                  <Route
                                    path="/editor/:draftId"
                                    component={Editor}
                                  />
                                  <Route path="/account" component={Account} />
                                  {hasRole(Roles.ADMIN) && (
                                    <Route path="/admin" component={Admin} />
                                  )}
                                  {process.env.REACT_APP_PRO_ACCOUNTS_STATE ===
                                    'ENABLED' && (
                                    <Route
                                      path="/pro-account"
                                      component={ProAccount}
                                    />
                                  )}
                                </>
                              ) : (
                                <Redirect to="/" />
                              )
                            }
                          />
                          {FEATURES.PHR.ENABLED && (
                            <Route path="/health" component={MyPhr} />
                          )}
                          {/* NOT FOUND PAGE */}
                          <Route path="*" component={NotFound} />
                        </Switch>
                      </Suspense>
                    </>
                  )}
                />
              </Switch>
            </Suspense>
            {showFooter && <Footer />}
            <UnblurSensitiveContentConfirmationModal />
          </div>
        </div>
      </ScrollProvider>
      {background && (
        <Route
          path={`/${libraryPath.ASSET}/:hashId`}
          component={LibraryAssetViewer.Modal}
        />
      )}
    </Wrapper>
  );
}

export default withProfiler(function AppWrapper() {
  return (
    <ErrorBoundary fallback={<Error />}>
      <QueryClientProvider client={queryClient}>
        {/* TODO: merge Auth and Profile */}
        <Auth.Provider fallback={<LoadingComponent />}>
          <ProfileContext.Provider>
            <OCRProvider>
              <SettingsProvider>
                <PartnerProvider>
                  <BrowserRouter>
                    <Suspense fallback={<LoadingComponent />}>
                      <App />
                      <OldBrowserWarning />
                    </Suspense>
                    {FEATURES.COOKIES_CONSENT.ENABLED && <CookiesPrompt />}
                  </BrowserRouter>
                </PartnerProvider>
              </SettingsProvider>
              <Portal>
                <ReactQueryDevtools initialIsOpen={false} />
              </Portal>
            </OCRProvider>
          </ProfileContext.Provider>
        </Auth.Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
});
