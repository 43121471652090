import { DEFAULT_STORYBLOCK_SUFFIX } from 'my-phr/const';
import api, { publicApi } from './axios';

const buildLibraryApi = (axios) => ({
  getLibraryContentByIds(ids = []) {
    const hashids = ids.map(encodeURI).join(',');
    return axios
      .get('/library', {
        params: { hashids },
      })
      .then(({ data }) => data);
  },
  getLibraryBySearchIndexContentByIds(indexes, ids = []) {
    const hashids = ids.map(encodeURI).join(', ');
    const indexArr = indexes.map(encodeURI).join(', ');
    return axios
      .get('/library/index', {
        params: { index: indexArr, ids: hashids },
      })
      .then(({ data }) => data);
  },
  getLibraryMetaByIds(ids = []) {
    const encodedIds = ids.map(encodeURI).join(',');
    return axios
      .get('/library/metadata', {
        params: { ids: encodedIds },
      })
      .then(({ data }) => data);
  },
  getGlossaryBySearchTerm(data) {
    return axios
      .get('/library/glossary', {
        params: {
          query: data.query,
          type: data.type,
        },
      })
      .then(({ data }) => data);
  },
  getStoryBlocksById(ids = []) {
    const encodedIds = ids.map(encodeURI).join(',');
    return axios
      .get('/library/storyblock', {
        params: { ids: encodedIds },
      })
      .then(({ data }) => data);
  },
  getJourneyById(id) {
    return axios
      .get('library/journey', {
        params: {
          ids: id,
        },
      })
      .then(({ data }) => data);
  },
  getJourneyCollectionById(id) {
    return axios
      .get('library/collection', {
        params: {
          ids: id,
        },
      })
      .then(({ data }) => data);
  },
  getLibrarySearchSummary(query) {
    return axios
      .get('/library/search/summary', {
        params: { query },
      })
      .then(({ data }) => data);
  },
  getLibrarySearch(data) {
    return axios
      .get('/library/search', {
        params: {
          query: data.query,
          type: data.type,
          offset: data?.offset,
          limit: data?.limit,
          startDate: data?.startDate,
          endDate: data?.endDate,
        },
      })
      .then(({ data }) => data);
  },
  getDefaultStoryblock(type) {
    return axios
      .get('/library/autocomplete/storyblock', {
        params: {
          type: type.concat(DEFAULT_STORYBLOCK_SUFFIX),
        },
      })
      .then(({ data }) => data);
  },
  getAutocompletion(query, endpoint, params) {
    return axios
      .get(endpoint || '/library/autocomplete', {
        params: { query, ...params },
      })
      .then(({ data }) => data);
  },
  getSpellingCorrection(params) {
    return axios
      .get(`/library/suggest`, {
        params,
      })
      .then(({ data }) => data)
      .then(({ data }) => data)
      .catch(() => {});
  },
});

export const LibraryApiPublic = buildLibraryApi(publicApi);

export default buildLibraryApi(api);
