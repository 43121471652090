import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useMedia } from 'hooks';

import { ContentContainer, Credits } from 'components';
import { Button } from 'components/forms';
import { ChevronRight } from 'components/icons';
import { WideCard } from 'components/WideCard';
import { GradientBackground, PictureResource } from 'components/media';
import { getFocalPosition } from 'utils';

import { Sponsors } from 'journals/components';
import {
  BlurredLayer,
  blurredStyle,
  hasSensitiveTags,
} from 'components/BlurredLayer';
import { mergeAll } from 'ramda';
import preamblePropTypes from '../propTypes/preamble';

function FrontPage({ pages, preamble, proceedUrl, imageSectionStyle }) {
  const { t } = useTranslation();
  const { title, about, heroes, subTitle } = preamble;

  const hero = heroes?.[0] ?? {};

  const focalPoint = hero.focalPoint;
  const coverAlt = hero.alt || hero.name;
  const coverName = hero.label || hero.name;
  const coverCredit = hero.credit;

  const { position, isValid } = getFocalPosition(focalPoint);

  const pictureStyles = {
    objectPosition: isValid ? position : 'center',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  };
  const isSensitive = hasSensitiveTags(hero);
  const largeScreen = useMedia(useMedia.LARGE);

  return (
    <div className="flex h-full grow flex-col">
      <div className="flex grow flex-col" style={imageSectionStyle}>
        <Sponsors wrapperClassName="px-6" logos={preamble?.logos} />
        <div className="relative h-full grow text-white">
          <BlurredLayer
            className="absolute z-0"
            isSensitive={isSensitive}
            alignmentClassNames="items-center justify-start pt-12 pr-6"
          >
            {({ isBlurred }) => {
              return (
                <>
                  <PictureResource
                    config={{
                      ...hero,
                      alt: coverAlt,
                    }}
                    style={
                      isBlurred
                        ? mergeAll([pictureStyles, blurredStyle])
                        : pictureStyles
                    }
                    loading="eager"
                  />
                  <GradientBackground className="absolute inset-0" />
                </>
              );
            }}
          </BlurredLayer>

          {largeScreen ? (
            <div className="pointer-events-none relative flex h-full flex-col">
              <div className="flex h-full flex-col justify-center px-smd-xxl py-smd-xl">
                <h1 className="break-word pointer-events-auto w-full shrink-0 overflow-hidden text-smd-h1 font-semibold xl:w-2/3 xl:text-smd-t1 ">
                  {title}
                </h1>
                <p className="lg:line-clamp-2 pointer-events-auto hidden w-full shrink-0 pt-smd-xs text-smd-sm  xl:w-2/3 xl:text-smd-base">
                  {subTitle}
                </p>
                <p
                  className="lg:inherit line-clamp-4 pointer-events-auto w-full shrink-0 overflow-hidden pt-smd-base text-smd-base xl:w-2/3  xl:pt-smd-md xl:text-smd-h4 2xl:text-smd-h3"
                  dangerouslySetInnerHTML={{ __html: about }}
                />
                <Link
                  to={proceedUrl}
                  className="smd-focus-visible-primary flex-center pointer-events-auto mt-smd-xl shrink-0 space-x-3 rounded bg-smd-accent font-light focus:outline-none lg:w-min"
                >
                  <Button.Primary tabIndex={-1} className="pointer-events-auto">
                    <div className="lg:whitespace-nowrap ">
                      {t('journals.proceed-to-healthjournal')}
                    </div>
                    <div>
                      <ChevronRight strokeWidth="2" className=" h-5 w-5" />{' '}
                    </div>
                  </Button.Primary>
                </Link>
              </div>
              <div className="relative mr-smd-xl mb-smd-xl shrink-0">
                <Credits name={coverName} source={coverCredit?.name} />
              </div>
            </div>
          ) : (
            <div className="pointer-events-none relative flex h-full flex-col">
              <div className="flex h-full flex-col justify-center  px-smd-xxl py-smd-xl">
                <h1 className="break-word pointer-events-auto w-full shrink-0 overflow-hidden text-center text-smd-h2 font-semibold xl:w-2/3 xl:text-smd-t1">
                  {title}
                </h1>
              </div>
              <Link
                to={proceedUrl}
                className="flex-center pointer-events-auto mx-smd-xxl shrink-0"
              >
                <Button.Primary tabIndex={-1} className="w-full">
                  <div className="whitespace-nowrap ">
                    {t('journals.proceed-to-healthjournal')}
                  </div>
                </Button.Primary>
              </Link>
              <div className="relative mx-smd-sm mb-smd-sm mt-smd-3xl shrink-0">
                <Credits name={coverName} source={coverCredit?.name} />
              </div>
            </div>
          )}
        </div>
      </div>

      {Boolean(pages?.length) && (
        <div className="overflow-hidden p-4 !pb-20 lg:p-8">
          <ContentContainer maxWidthClassName="max-w-4xl">
            <div className="grid gap-4">
              {pages.map((item) => (
                <Link
                  to={item.url}
                  key={item.key}
                  className="smd-focus-visible-primary overflow-hidden rounded-sm shadow-md"
                >
                  <WideCard
                    asset={item.featuredAsset}
                    fallbackThumbnailUrl={item?.thumbnail}
                    synonyms={[item?.title]}
                    title={item?.name}
                    plainText={item.description}
                  />
                </Link>
              ))}
            </div>
          </ContentContainer>
        </div>
      )}
    </div>
  );
}

FrontPage.propTypes = {
  preamble: preamblePropTypes.shape.isRequired,
  pages: PropTypes.array,
};

FrontPage.defaultProps = {
  preamble: preamblePropTypes.defaults,
};

export default FrontPage;
