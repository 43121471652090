import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { useAds, useMedia, useOutsideClick, useScrollIntoView } from 'hooks';
import { getSlugHead, slugifyObject } from 'utils/slug';

import { IndexButton, MobileIndexTrigger } from 'components';
import { SEOHead } from 'components/SEO';
import { Ad, AD_SIZE } from 'components/Ad';

import {
  ViewerAsideNav,
  ViewerBreadcrumb,
  ViewerLayout,
  ViewerTableOfContent,
} from 'components/viewer';

import {
  useGetJourneys,
  useJourneyCollection,
  useJourneyTableOfContentsMapper,
} from 'hooks/journeys';

import { useLibraryMeta } from 'library/hooks';

import {
  CollectionContent,
  CollectionContentSkeleton,
  JourneyCollections,
  JourneyInfoSkeleton,
  JourneyInfo,
} from 'journals/journeys/components';

import { ShareModal, SharePreview } from 'components/Share';
import { useLocalStorage } from 'react-use';
import { useJourneyBreadcrumb } from './hooks';

const SHARE_TARGETS = {
  JOURNEY: 'JOURNEY',
  COLLECTION: 'COLLECTION',
};

const SHARE_INITIAL_STATE = {
  isOpen: false,
  target: null,
};

const JOURNEY_HISTORY_KEY = 'smd-journey-visit-history';

function Journey() {
  const { t } = useTranslation();

  const {
    journeyId: slugJourneyId,
    collectionId: slugPreselectedCollectionId,
    subCollectionId: slugPreselectedSubCollectionId,
  } = useParams();

  const journeyId = getSlugHead(slugJourneyId);
  const preselectedCollectionId = getSlugHead(slugPreselectedCollectionId);
  const preselectedSubCollectionId = getSlugHead(
    slugPreselectedSubCollectionId
  );

  const { data: journeyMeta } = useLibraryMeta(
    [preselectedCollectionId, journeyId].filter(Boolean)
  );

  const adsConfig = useAds(['placements', 'channels', 'top']);

  const scrollIntoView = useScrollIntoView();

  const collectionContentRef = useRef();

  const [currentSlugCollectionId, setCollectionId] = useState();
  const collectionId = getSlugHead(currentSlugCollectionId);

  const [currentSlugSubCollectionId, setSubCollectionId] = useState();
  const subCollectionId = getSlugHead(currentSlugSubCollectionId);

  const [sharing, setSharing] = useState(SHARE_INITIAL_STATE);

  useEffect(() => {
    setCollectionId(preselectedCollectionId);
  }, [preselectedCollectionId]);

  useEffect(() => {
    setSubCollectionId(preselectedSubCollectionId);
  }, [preselectedSubCollectionId]);

  const isLarge = useMedia(useMedia.LARGE);

  const [tocOpen, setTocOpen] = useState(isLarge);

  const closeTocOnOutsideClick = (event) => {
    if (tocOpen && !isLarge) {
      event?.preventDefault?.();
      event?.stopPropagation?.();
      setTocOpen(false);
    }
  };

  const tocRef = useOutsideClick(closeTocOnOutsideClick, 'click', true);

  const [visitedJourneyHistory = {}, setVisitedJourneyHistory] =
    useLocalStorage(JOURNEY_HISTORY_KEY, {});

  const { isFetched: isJourneyFetched, data } = useGetJourneys(journeyId, {
    onSuccess: () => {
      if (visitedJourneyHistory[journeyId]) {
        scrollIntoView(collectionContentRef?.current);
      } else {
        setVisitedJourneyHistory((currentHistory) => ({
          ...currentHistory,
          [journeyId]: true,
        }));
      }
    },
  });

  const { data: collection, isLoading: isFetchingCollection } =
    useJourneyCollection(getSlugHead(collectionId), {
      enabled: Boolean(collectionId),
      onSuccess: () => {
        scrollIntoView(collectionContentRef?.current);
      },
    });

  const journey = data?.[journeyId];

  const tableOfContents = useJourneyTableOfContentsMapper(journey);

  const onTOCItemSelected = (value) => {
    const { id } = value;
    const slugId = slugifyObject(id, value.label);
    if (id === collectionId && value.type === 'collection') return;
    if (value.type === 'collection') {
      setCollectionId(slugId);
      setSubCollectionId();
      scrollIntoView(collectionContentRef?.current);
      window.history.replaceState({}, 1, `/journey/${slugJourneyId}/${slugId}`);
    } else {
      setSubCollectionId(slugId);
      subCollectionRef?.current?.scrollTo(id);
      window.history.replaceState(
        {},
        1,
        `/journey/${slugJourneyId}/${currentSlugCollectionId}/${slugId}`
      );
    }
  };

  const journeyShareURL = `${window.location.origin}/journey/${slugJourneyId}`;
  const collectionShareURL = window.location.href;
  const isSharingCollection = sharing.target === SHARE_TARGETS.COLLECTION;
  const journeyShareProps = {
    name: journey?.name,
    description: journey?.description,
    thumbnail: journey?.thumbnail,
    type: 'journey',
  };

  const collectionShareProps = {
    name: collection?.name,
    thumbnail: collection?.thumbnail,
    description: journey?.description,
    type: 'collection',
  };

  const subCollectionRef = useRef();

  const sharePreviewProps = isSharingCollection
    ? collectionShareProps
    : journeyShareProps;

  const breadcrumb = useJourneyBreadcrumb(
    journey,
    collectionId,
    subCollectionId
  );

  useEffect(() => {
    if (preselectedSubCollectionId) {
      subCollectionRef?.current?.scrollTo(preselectedSubCollectionId);
    }
  }, [collection, subCollectionRef, preselectedSubCollectionId]);

  const history = useHistory();

  const isMedium = useMedia(useMedia.MEDIUM);
  const shouldHideHero =
    !isMedium && !isNil(subCollectionId || slugPreselectedSubCollectionId);

  return (
    <>
      <SEOHead config={journeyMeta?.[0]} />
      <ViewerLayout
        asideClassName={classnames(
          'z-20 shrink-0 bg-white',
          {
            'border-r border-smd': tocOpen,
          },
          { 'fixed top-14 bottom-0 right-0 overflow-auto': !isLarge }
        )}
        breadcrumb={
          <ViewerBreadcrumb
            onItemClick={(url) => {
              setCollectionId(null);
              setSubCollectionId(null);
              history.push(url);
            }}
            breadcrumb={breadcrumb}
          />
        }
        sidebar={
          isLarge ? (
            <IndexButton
              onClick={() => setTocOpen(true)}
              className={{ hidden: tocOpen }}
            />
          ) : null
        }
        sidebarClassName="bg-white"
        hero={
          isJourneyFetched && !shouldHideHero ? (
            <JourneyInfo
              {...journey}
              onShare={() =>
                setSharing({
                  isOpen: true,
                  target: SHARE_TARGETS.JOURNEY,
                })
              }
            />
          ) : (
            <>{!shouldHideHero ? <JourneyInfoSkeleton /> : null}</>
          )
        }
        footer={
          !isLarge ? (
            <MobileIndexTrigger
              isTOCOpen={tocOpen}
              onTOCStateToggle={() => setTocOpen((open) => !open)}
              onShare={() =>
                setSharing({
                  isOpen: true,
                  target: isSharingCollection
                    ? SHARE_TARGETS.COLLECTION
                    : SHARE_TARGETS.JOURNEY,
                })
              }
            />
          ) : null
        }
        aside={
          <ViewerAsideNav
            className={classnames({ hidden: !tocOpen })}
            onClose={() => setTocOpen(false)}
            ref={tocRef}
          >
            {adsConfig?.toc?.top?.enabled && (
              <Ad
                className="pb-2"
                slot={adsConfig?.toc?.top?.slot}
                dimensions={{
                  base: AD_SIZE.HORIZONTAL.SMART_PHONE_BANNER_SMALL,
                }}
              />
            )}
            {tableOfContents && (
              <ViewerTableOfContent
                root={tableOfContents}
                activeId={collectionId}
                secondaryActiveId={subCollectionId}
                onSelect={onTOCItemSelected}
                tracksCompletion={false}
                className="index-table-of-contents"
                title={
                  <div className="pb-2 font-semibold text-smd-accent">
                    {t('common.collection', { count: Number.MAX_VALUE })}
                  </div>
                }
              />
            )}
            {adsConfig?.toc?.bottom?.enabled && (
              <Ad
                className="pt-2"
                slot={adsConfig?.toc?.bottom?.slot}
                dimensions={{
                  base: AD_SIZE.HORIZONTAL.SMART_PHONE_BANNER_SMALL,
                }}
              />
            )}
          </ViewerAsideNav>
        }
        main={
          isNil(collectionId) && isJourneyFetched ? (
            <JourneyCollections
              ref={collectionContentRef}
              onCollectionSelected={(id, name) =>
                onTOCItemSelected({ id, type: 'collection', label: name })
              }
              {...journey}
            />
          ) : (
            <>
              <div ref={collectionContentRef} className="lg:scroll-mt-24" />
              {isFetchingCollection || !isJourneyFetched ? (
                <CollectionContentSkeleton />
              ) : (
                <CollectionContent
                  ref={subCollectionRef}
                  subCollectionId={subCollectionId}
                  onSectionChanged={setSubCollectionId}
                  onShare={() =>
                    setSharing({
                      isOpen: true,
                      target: SHARE_TARGETS.COLLECTION,
                    })
                  }
                  {...collection}
                />
              )}
            </>
          )
        }
      />
      <ShareModal
        isOpen={sharing?.isOpen}
        onClose={() => setSharing(SHARE_INITIAL_STATE)}
        shareUrl={isSharingCollection ? collectionShareURL : journeyShareURL}
        withNewsletterParam={false}
      >
        <SharePreview {...sharePreviewProps} />
      </ShareModal>
    </>
  );
}

export default Journey;
