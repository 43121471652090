import PropTypes from 'prop-types';
import { secondsToMilliseconds } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Attention } from 'components';
import { Bell } from 'components/icons';
import { useHasUnreadNotifications } from 'hooks';

const REFETCH_INTERVAL = secondsToMilliseconds(30);

export default function Notifier({ onClick, open }) {
  const { data: hasUnreadNotifications = false, isFetched } =
    useHasUnreadNotifications({
      refetchInterval: REFETCH_INTERVAL,
    });
  const shouldAlert = isFetched && hasUnreadNotifications;
  const { t } = useTranslation();
  return (
    <button
      className="smd-focus-visible-primary flex flex-col items-center rounded text-smd-accent outline-none"
      onClick={onClick}
      aria-label={
        open ? t('labels.notifications.hide') : t('labels.notifications.show')
      }
      aria-haspopup="true"
      aria-expanded={open}
    >
      {shouldAlert && (
        <Attention>
          <Bell className="h-6 w-6 stroke-1" />
        </Attention>
      )}
      {!shouldAlert && <Bell className="h-6 w-6 stroke-1" />}
      <span className="hidden text-smd-2xs lg:block">
        {t('notifications.alerts')}
      </span>
    </button>
  );
}

Notifier.propTypes = {
  onClick: PropTypes.func.isRequired,
};
