import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useOCRStateContext } from 'OCRContext';
import { baseUrl } from 'utils';
import { useAuth } from 'Auth';
import { Button } from 'components/forms';
import { LogoHealth } from 'components/icons';

import { useUserSubscriptionStatus } from 'my-phr/hooks';
import { MenuNavLink } from './MenuNavLink';

function StoryMDHealthButton() {
  const { t } = useTranslation();
  const location = useLocation();

  const { state: ocrState } = useOCRStateContext();
  const isActiveOCR = Boolean(ocrState.ocrModal) || Boolean(ocrState.ocrDialog);

  const { authenticated } = useAuth();
  const { isSubscriptionActive, isFetched } = useUserSubscriptionStatus();

  const isHealthActive = authenticated && isFetched && isSubscriptionActive;

  const action = isHealthActive ? '' : t('main-menu.explore');

  const linkClassName =
    'smd-focus-visible-primary flex items-center truncate rounded-sm py-1 font-semibold lg:px-2';

  const healthClassName = location.pathname.includes('/health')
    ? 'border-1 border-smd-accent'
    : '';

  return isActiveOCR ? (
    <MenuNavLink to="/health" aria-label={t('labels.menu.phr')}>
      {t('main-menu.my-phr')}
    </MenuNavLink>
  ) : (
    <Button.Secondary className={classnames(healthClassName, 'h-[40px]')}>
      <a
        href={baseUrl + '/health'}
        rel="noopener noreferrer"
        className={classnames(linkClassName)}
        aria-label={t('labels.menu.phr')}
        tabIndex={-1}
      >
        <div className="flex items-center gap-1 truncate text-smd-base tracking-wide text-smd-gray-darker">
          <span>{action}</span>
          <LogoHealth className="h-6 w-full" />
        </div>
      </a>
    </Button.Secondary>
  );
}

export default StoryMDHealthButton;
