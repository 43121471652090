import { useAuth } from 'Auth';
import { PaymentApi } from 'api';
import payment from 'api/payment';

import { useMutation, useQuery, useQueryClient } from 'react-query';

const ACTIVE_SUBSCRIPTION_STATUS = 'ACTIVE';

const keys = {
  root: () => ['payment'],
  subscriptionsPlans: () => [...keys.root(), 'subscriptions-plans'],
  userSubscriptions: () => [...keys.root(), 'user-subscriptions'],
  subscriptionStatus: () => [...keys.userSubscriptions(), 'status'],
};

export function usePHRSubscriptionsPlans(options) {
  return useQuery(
    keys.subscriptionsPlans(),
    PaymentApi.getSubscriptionsPlans,
    options
  );
}

export function useUserSubscriptions() {
  const { data: subscriptions, isLoading } = useQuery(
    keys.userSubscriptions(),
    PaymentApi.getSubscriptions
  );
  return { subscriptions, isLoading };
}

export function useCreatePHRSubscription(options) {
  const queryClient = useQueryClient();
  const prefetchSubscriptionStatus = () => {
    return queryClient.prefetchQuery(
      keys.subscriptionStatus(),
      PaymentApi.getSubscriptionStatus
    );
  };
  const { mutate, isLoading, isSuccess } = useMutation(
    payment.createSubscription,
    options
  );
  return {
    createSubscription: mutate,
    isCreatingSubscription: isLoading,
    isSubscriptionCreated: isSuccess,
    prefetchSubscriptionStatus,
  };
}

export function useUserSubscriptionStatus(options) {
  const { authenticated } = useAuth();
  const { data, isLoading, isFetched } = useQuery(
    keys.subscriptionStatus(),
    PaymentApi.getSubscriptionStatus,
    {
      enabled: authenticated,
      retry: false,
      cacheTime: 0,
      ...options,
    }
  );
  return {
    isSubscriptionActive: data?.status === ACTIVE_SUBSCRIPTION_STATUS,
    isCancelled: data?.cancelled,
    status: data?.status,
    isFetched,
    isLoading,
  };
}

export function useDeletePHRSubscription(options) {
  const { mutate } = useMutation(payment.deleteSubscription, options);
  return {
    deleteSubscription: mutate,
  };
}

export function usePHRSubscriptionStatus(options) {
  const { mutate } = useMutation(payment.updateSubscriptionStatus, options);
  return {
    updateSubscriptionStatus: mutate,
  };
}

export function useRedeemSubscriptionCode(options) {
  const queryClient = useQueryClient();
  const prefetchSubscriptionStatus = () => {
    return queryClient.prefetchQuery(
      keys.subscriptionStatus(),
      PaymentApi.getSubscriptionStatus
    );
  };
  const { mutate, ...rest } = useMutation(
    payment.redeemSubscriptionCode,
    options
  );
  return {
    redeemSubscriptionCode: mutate,
    ...rest,
    prefetchSubscriptionStatus,
  };
}
