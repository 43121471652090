import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { useLibraryApi } from 'library/libraryApiContext';
import { includes } from 'ramda';
import { MANUAL_INPUT_CONFIG_TYPE } from 'my-phr/layout/modules/ManualInputs/const';
import useDebounce from './useDebounce';

const TESTS_CATEGORY_KEY = 'lab_test';

function isTest(entry) {
  return (
    Boolean(entry?.categories?.length) &&
    includes(TESTS_CATEGORY_KEY, entry?.categories)
  );
}

function mapObservationsType(entries = []) {
  return entries.map((entry) => ({
    ...entry,
    type: isTest(entry) ? MANUAL_INPUT_CONFIG_TYPE.OBSERVATION : entry?.type,
  }));
}

function useSearchSuggestions(debounce = 0, endpoint, params, options = {}) {
  const api = useLibraryApi();

  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, debounce);

  const {
    data: suggestions,
    isFetching,
    isFetched,
    refetch,
  } = useQuery(
    ['search', 'suggestions', debouncedSearch],
    () =>
      api.getAutocompletion(debouncedSearch, endpoint, params).catch(() => []),
    {
      initialData: [],
      select: useCallback((response) => response?.data ?? [], []),
      ...options,
    }
  );

  const fetch = useCallback(({ value }) => setSearch(value), []);

  const clear = useCallback(() => setSearch(''), []);

  return {
    suggestions: mapObservationsType(suggestions),
    isFetching,
    fetch,
    clear,
    isFetched,
    refetch,
  };
}

export default useSearchSuggestions;
