import { not } from 'ramda';
import { UcumLhcUtils } from '@lhncbc/ucum-lhc';
import FEATURES from 'features';
import { isValidDate } from 'utils';

const DEFAULT_UNIT_NAME = 1;

export const ucumUtils = UcumLhcUtils.getInstance();

export function validUcumUnitSuggestions(unit) {
  const result = ucumUtils.validateUnitString(unit, true);

  if (result.status === 'error') return [];

  const suggestionsForInvalidUnit = result?.suggestions?.[0]?.units;
  const matchedUnit = result?.unit ? [Object.values(result?.unit)] : null;
  const suggestions = matchedUnit ?? suggestionsForInvalidUnit;

  if (!suggestions?.length) return [];

  return suggestions;
}

export function validateUcumUnit(unit) {
  const validationResult =
    typeof unit === 'string' && ucumUtils.validateUnitString(unit);
  return {
    isValidUnit: Boolean(validationResult.ucumCode),
    validationResult,
  };
}

export function ucumConvertValues({
  fromUnit,
  fromValue,
  toUnit,
  molecularWeight,
}) {
  const conversionResult = ucumUtils.convertUnitTo(
    fromUnit,
    fromValue,
    toUnit,
    false,
    molecularWeight
  );

  const unitName = conversionResult.toUnit?.name_;

  const containDefaultValueOne =
    unitName?.startsWith?.(DEFAULT_UNIT_NAME) ||
    unitName?.endsWith?.(DEFAULT_UNIT_NAME) ||
    unitName === DEFAULT_UNIT_NAME;

  const nameShown = containDefaultValueOne ? fromUnit : unitName;

  return {
    convertedValue: conversionResult.toVal,
    convertedUnitSymbol: containDefaultValueOne
      ? nameShown
      : conversionResult.toUnit?.printSymbol_,
    conversionResult,
  };
}

export function validateAndConvertToSelf({ unit, value, molecularWeight }) {
  let validationResult;

  try {
    validationResult = validateUcumUnit(unit);
  } catch {
    return { unit, value };
  }

  if (!validationResult?.isValidUnit) return { unit, value };

  const { convertedUnitSymbol, conversionResult } = ucumConvertValues({
    fromUnit: unit,
    fromValue: value,
    toUnit: unit,
    molecularWeight: molecularWeight,
  });

  if (conversionResult.toUnit?.name_ === DEFAULT_UNIT_NAME) {
    return {
      unit,
      value,
    };
  }

  return {
    unit: convertedUnitSymbol || conversionResult.toUnit?.name_ || unit,
    value,
  };
}

export function findFirstValidUnit(data = []) {
  const firstValidUnit = data.find((x) => {
    const { isValidUnit } = validateUcumUnit(x?.unit);
    if (isValidUnit) return x;
    return false;
  });

  return { baseUnit: firstValidUnit?.unit };
}

function convertDateToTimestamp(entry) {
  const d = new Date(entry.effectiveTs);
  if (!isValidDate(d)) return null;
  return d.getTime();
}

export function convertChartData(data = []) {
  return data
    .map((x) => {
      const effectiveTs = convertDateToTimestamp(x);

      const { conversionResult } = ucumConvertValues({
        fromUnit: x?.unit,
        fromValue: x?.value,
        toUnit: data?.[0]?.unit,
        molecularWeight: x?.storyblock?.molecularWeight,
      });

      const isDefaultUnit =
        conversionResult.toUnit?.name_ === DEFAULT_UNIT_NAME;

      const isConversionSuccess = conversionResult.status === 'succeeded';
      if (
        FEATURES.PHR.EXCLUDE_INVALID_UNITS_DASHBOARD &&
        not(
          effectiveTs &&
            Boolean(x?.unit) &&
            validateUcumUnit(x?.unit).isValidUnit
        )
      ) {
        return null;
      }
      //TODO EFFECTIVE DATE CONVERTION TO TIMESTAMP MAYBE NOT NEEDED
      return {
        ...x,
        effectiveTs,
        value: isConversionSuccess ? conversionResult?.toVal : x.value,
        unit:
          isDefaultUnit || !isConversionSuccess
            ? x?.unit
            : conversionResult?.toUnit?.csCode_,
      };
    })
    .filter(Boolean);
}
