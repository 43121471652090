import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Menu, X } from 'components/icons';
import { useSocialMediaProviders } from 'hooks';

function ListMenu({ children }) {
  return <div className="grid items-start gap-2">{children}</div>;
}

function ListMenuToggle({ open, ...props }) {
  const { t } = useTranslation();
  return (
    <button
      className="smd-focus-visible-primary rounded outline-none"
      aria-label={
        open
          ? t('labels.common.list-menu-close')
          : t('labels.common.list-menu-open')
      }
      aria-haspopup="menu"
      aria-expanded={open}
      data-testid="menuHamburgerButton"
      {...props}
    >
      {open ? <X className="h-6 w-6 stroke-2" /> : <Menu className="h-6 w-6" />}
    </button>
  );
}

function ListMenuFooter() {
  const { t } = useTranslation();

  const socials = useSocialMediaProviders();

  return (
    <div className="grid gap-2 border-t-2 bg-white pt-2 text-smd-sm text-smd-gray-dark lg:gap-0 lg:p-4">
      <div className="flex flex-col space-y-2 pb-2">
        <ul className="flex w-full grow flex-col space-y-4 py-2">
          <li>
            <Link
              to="/privacy-policy"
              className="smd-focus-visible-primary rounded-sm"
              data-testid="privacyPolicyHamburgerLink"
            >
              {t('legal.privacy-policy')}
            </Link>
          </li>
          <li>
            <Link
              to="/terms"
              className="smd-focus-visible-primary rounded-sm"
              data-testid="termsOfServiceHamburgerLink"
            >
              {t('legal.terms-of-service')}
            </Link>
          </li>
          <li>
            <Link
              to="/accessibility"
              className="smd-focus-visible-primary rounded-sm"
              data-testid="accessibilityHamburgerLink"
            >
              {t('legal.accessibility')}
            </Link>
          </li>
        </ul>
        <ul className="flex flex-wrap gap-y-4 gap-x-8 py-2 lg:gap-x-6">
          {socials.map(({ type, url, icon: Icon }) => (
            <li key={type}>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="smd-focus-visible-primary block rounded-sm"
                data-testid={`${type}HamburgerIcon`}
              >
                <Icon className="h-8 w-8 fill-smd-gray-dark" />
                <span className="sr-only">
                  {t('labels.common.find-us', {
                    social: type,
                  })}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div
        className="w-full text-smd-xs italic text-smd-gray"
        data-testid="storyMdInformationMessageHamburger"
      >
        &copy; {new Date().getFullYear()} {t('landing.reserved-rights')}
      </div>
    </div>
  );
}

ListMenu.Footer = ListMenuFooter;
ListMenu.Toggle = ListMenuToggle;

export default ListMenu;
