import { useCallback } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { getSlugHead, slugifyObject } from 'utils/slug';
import { UrlResolverProvider } from 'UrlResolverContext';
import { libraryPath } from '../const';
import { useTopicLastLocation } from '../hooks';
import LibraryTopicViewerPage, { ROOT_ID } from './LibraryTopicViewerPage';

function LibraryTopicViewerRoutedPage() {
  const history = useHistory();
  const { hashId: hashIdSlug } = useParams();
  const hashId = getSlugHead(hashIdSlug);

  const { popTopicLastLocation } = useTopicLastLocation({
    onEvent: ({ method, lastLocation }) => {
      if (method === 'pop') {
        history.push(lastLocation);
      }
    },
  });

  const { path, url } = useRouteMatch();

  const linkagePath = `${path}/${libraryPath.PAGE}/:linkageId`;
  const linkageMatch = useRouteMatch(linkagePath);

  const linkageIdSlug = linkageMatch?.params?.linkageId;
  const linkageId = getSlugHead(linkageIdSlug);

  const navigateTo = useCallback(
    (path) => {
      if (path !== history.location.pathname) {
        history.push(path);
      }
    },
    [history]
  );

  const getPageUrl = useCallback(
    (page) => {
      const containerSlug = page?.slug
        ? slugifyObject(page?.slug)
        : slugifyObject(page.id, page.label);
      return `${url}/${libraryPath.PAGE}/${containerSlug}`;
    },
    [url]
  );

  const resolvePageUrl = useCallback(
    (page) => (page.id !== ROOT_ID ? getPageUrl(page) : url),
    [url, getPageUrl]
  );

  const onNavigate = useCallback(
    (page) => navigateTo(page?.id ? getPageUrl(page) : url),
    [url, navigateTo, getPageUrl]
  );

  return (
    <UrlResolverProvider resolver={resolvePageUrl}>
      <LibraryTopicViewerPage
        hashId={hashId}
        linkageId={linkageId}
        onClose={popTopicLastLocation}
        onNavigate={onNavigate}
      />
    </UrlResolverProvider>
  );
}

export default LibraryTopicViewerRoutedPage;
