import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { head, last, path } from 'ramda';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isAfter, isSameDay, setHours, setMinutes } from 'date-fns';
import { isFunction, isMidnightTime } from 'utils';
import { Clock } from 'components/icons';
import { useMedia } from 'hooks';
import { Button, Label } from '..';
import { registerFormElement } from './utils';
import FormController from './FormController';
import { CustomDateInput } from './FormInputDate';

const base = [
  'border-smd-gray-light',
  'p-2',
  'leading-normal',
  'w-full',
  'placeholder:text-smd-tertiary',
  'smd-input-focus-primary',
];

const CustomTimeInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <div className="relative">
    <input
      value={value}
      className="smd-input-focus-primary h-11 w-[9.5rem] border-1 border-smd-gray-light bg-white px-2 md:w-[11.5rem]"
      onClick={onClick}
      ref={ref}
      placeholder={placeholder}
    />
    <Clock className="pointer-events-none absolute left-[7.5rem] top-2/4 h-5 w-5 -translate-y-2/4 transform stroke-2 text-smd-accent md:left-[9.5rem]" />
  </div>
));

function FormInputDateTime(props) {
  const {
    label,
    labelProps,
    register,
    name,
    id = name,
    rules,
    className,
    dateFormat,
    placeholder,
    errors,
    timeIntervals,
    timeCaption,
    defaultValue,
    customDateParser,
    min,
    max,
    setValue,
    ...rest
  } = props;

  const { t } = useTranslation();

  const _name = [...name.split('.')];
  const error = path(_name, errors);

  const placeholders = placeholder?.split?.(' ');

  const border = error
    ? 'border-red-500 focus:border-red-500 focus:!ring-offset-red-500'
    : 'border-smd focus:border-smd-accent';

  const classes = base.concat(border, className).join(' ');
  const handleKeyDownEvent = (event) => {
    if (event.key !== 'Backspace' && event.key !== 'Delete') {
      event.preventDefault();
    }
  };

  const [showTime, setShowTime] = useState(false);

  const filterTime = (time) => (min ? isAfter(time, min) : true);

  const currentDate = new Date();

  const startOfDay = setHours(setMinutes(new Date(), 1), 0);
  const endOfDay = setHours(setMinutes(new Date(), 59), 23);

  const currentTime = setHours(
    setMinutes(currentDate, currentDate.getMinutes()),
    currentDate.getHours()
  );

  const isMedium = useMedia(useMedia.MEDIUM);
  return (
    <FormController
      {...rest}
      id={id}
      errors={errors}
      rules={rules}
      withError={error}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, onBlur, ref } }) => {
        const pickerValue = isFunction(customDateParser)
          ? customDateParser(value)
          : value;
        const isSameDate = isSameDay(currentDate, pickerValue);

        // Time input is hidden by default, but when editing an entry which has a specific (non 00:00:00) time, we need to display it when the modal is opened.
        const isEditingNonMidnightRecord =
          pickerValue && !isMidnightTime(pickerValue);

        return (
          <>
            {label ? (
              <Label htmlFor={id} {...labelProps}>
                {label}
              </Label>
            ) : (
              <></>
            )}
            <div className="flex items-start gap-x-2">
              <ReactDatePicker
                ref={(element) => {
                  element && ref(element.input);
                }}
                dropdownMode="select"
                showPopperArrow={false}
                selected={pickerValue}
                onChange={onChange}
                onBlur={onBlur}
                timeIntervals={60}
                withPortal={!isMedium}
                defaultValue={new Date()}
                dateFormat={dateFormat}
                className={classes}
                placeholderText={head(placeholders)}
                onKeyDown={handleKeyDownEvent}
                minDate={min}
                maxDate={max}
                filterTime={filterTime}
                popperPlacement="auto"
                customInput={<CustomDateInput />}
                showYearDropdown
                showMonthDropdown
                wrapperClassName="!w-fit"
              />
              {showTime || isEditingNonMidnightRecord ? (
                <div className="gap-2 sm:flex">
                  <ReactDatePicker
                    onChange={onChange}
                    showTimeSelect
                    showPopperArrow={false}
                    defaultValue={new Date()}
                    className={classes}
                    withPortal={!isMedium}
                    showTimeSelectOnly
                    placeholderText={last(placeholders)}
                    timeIntervals={15}
                    minTime={startOfDay}
                    maxTime={isSameDate ? currentTime : endOfDay}
                    selected={pickerValue}
                    timeCaption="Time"
                    customInput={<CustomTimeInput />}
                    dateFormat="h:mm aa"
                  />
                  <Button.Ghost
                    style={{ fontSize: '14px' }}
                    onClick={() => {
                      setShowTime(false);
                      pickerValue &&
                        setValue(name, setHours(setMinutes(pickerValue, 0), 0));
                    }}
                  >
                    {t('my-phr.manual-inputs.remove-time')}
                  </Button.Ghost>
                </div>
              ) : (
                <div className="flex h-11 items-center">
                  <Button.Ghost
                    style={{ fontSize: '14px' }}
                    onClick={() => {
                      setShowTime(true);
                    }}
                  >
                    {t('my-phr.manual-inputs.add-time')}
                  </Button.Ghost>
                </div>
              )}
            </div>
          </>
        );
      }}
    />
  );
}

registerFormElement(FormInputDateTime);

export default FormInputDateTime;
