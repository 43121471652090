import { useEffect, useRef, useState } from 'react';
import { SYNC_STATUSES } from 'my-phr/const';
import { useConnectedSources, useSources } from './queryHooks';

function useSyncing(onSyncingEnd) {
  const [syncing, setSyncing] = useState(false);

  const { connectedSources } = useConnectedSources(syncing);
  const { sources } = useSources(syncing);

  const prevRef = useRef();

  useEffect(() => {
    const isSyncing =
      connectedSources?.some(
        (source) => source.syncStatus === SYNC_STATUSES.SYNCING
      ) ||
      sources?.some((source) => source.syncStatus === SYNC_STATUSES.SYNCING);
    if (isSyncing) {
      setSyncing(true);
    } else {
      setSyncing(false);
    }
  }, [connectedSources, sources, onSyncingEnd]);

  useEffect(() => {
    if (prevRef.current && !syncing) {
      onSyncingEnd?.();
    }
    prevRef.current = syncing;
  }, [syncing, onSyncingEnd]);

  return syncing;
}

export default useSyncing;
