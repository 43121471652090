import { toast as toastLibrary } from 'react-hot-toast';
import classnames from 'classnames';
import { Tick, X } from 'components/icons';
import { getI18n } from 'react-i18next';

const toastIcons = {
  success: (
    <span className="flex h-4 w-4 shrink-0 items-center justify-center rounded-full bg-smd-status-completed text-white">
      <Tick className="h-full w-full stroke-2 p-1" />
    </span>
  ),
};

function buildToast(content, options, className, icon = null) {
  return toastLibrary.custom((toast) => {
    // TODO migrate to internal instance of i18n and fix the broken tests
    const { t } = getI18n();

    return (
      <div
        className={classnames(
          'flex w-full max-w-lg items-center rounded shadow-lg',
          { 'pointer-events-none opacity-0': !toast.visible },
          className
        )}
      >
        {icon && <div className="p-4">{icon}</div>}
        <div className="grow pr-4 text-smd-sm">{content}</div>
        {!options?.hideCloseButton && (
          <div className="flex flex-none rounded hover:bg-smd-gray-dark">
            <button
              onClick={() => toastLibrary.dismiss(toast.id)}
              className="flex h-12 w-12 items-center justify-center"
              aria-label={t('labels.common.close')}
            >
              <X className="h-6 w-6 stroke-3" />
            </button>
          </div>
        )}
      </div>
    );
  }, options);
}

export const toast = {
  success: (content, options) =>
    buildToast(
      content,
      options,
      'bg-smd-gray-darker text-white',
      toastIcons.success
    ),
  error: (content, options) =>
    buildToast(content, options, 'bg-smd-red text-white'),
  info: (content, options) =>
    buildToast(content, options, 'bg-white text-black'),
  dismiss: toastLibrary.dismiss,
};
