import { useRef, useState } from 'react';
import axios from 'axios';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import classnames from 'classnames';

import { Alert } from 'components';
import ContentContainer from 'components/ContentContainer';
import { Button } from 'components/forms';
import Form from 'components/forms/Form';

import { namesWithSpacesAndSpecialChars, validEmailRegEx } from 'utils';

function Contact() {
  const { t } = useTranslation();

  const reCaptchaRef = useRef();
  const [mailState, setMailState] = useState();

  const { mutate: sendEmail, isLoading } = useMutation(
    async (data) =>
      axios.post(`${process.env.REACT_APP_LIBRARY_URL}/api/contact`, data),
    {
      onMutate: () => reCaptchaRef.current.reset(),
      onSuccess: (res) =>
        setMailState({
          type: 'success',
          msg: res?.data?.response ?? res?.data,
        }),
      onError: (err) => setMailState({ type: 'error', msg: err?.message }),
    }
  );

  const onSubmit = (data) => {
    reCaptchaRef.current
      .executeAsync()
      .then((token) => {
        data['g-recaptcha-response'] = token;
        return data;
      })
      .then((dataWithReCaptcha) => {
        const formData = new FormData();
        for (const entry of Object.entries(dataWithReCaptcha)) {
          formData.append(entry[0], entry[1]);
        }
        sendEmail(formData);
      });
  };

  const firstNameValue = t('account-settings.manage-profile.first-name');
  const lastNameValue = t('account-settings.manage-profile.last-name');
  const emailValue = t('common.email');
  const messageValue = t('common.message');

  return (
    <ContentContainer className="grow px-4">
      <div className="max-w-130 pb-8 pt-16">
        <h1
          className="mb-4 text-smd-t2 font-semibold text-smd-main"
          data-testid="contactUsPageTitle"
        >
          {t('common.contact')}
        </h1>
        <p className="text-smd-base" data-testid="contactUsPageDescription">
          {t('landing.contact-feedback')}
        </p>
      </div>
      <div className="flex flex-nowrap items-start space-x-8 pb-16">
        <div className="w-full max-w-130">
          <Form
            onSubmit={onSubmit}
            className={classnames('grid w-full grid-cols-2 gap-4 pt-8 pb-4', {
              'pointer-events-none': isLoading,
            })}
            resetFormOnSuccess
            defaultValues={{
              subject: t('contact.subject'),
            }}
            id="contact-form"
          >
            <Form.InputWrapper className="col-span-2 md:col-span-1">
              <Form.Input
                groupClassName="flex flex-col"
                placeholder={t('contact.placeholder-first-name')}
                data-testid="contactUsPageFirstNameField"
                label={t('contact.label', {
                  value: firstNameValue,
                })}
                name="name"
                rules={{
                  required: t('contact.enter', {
                    value: firstNameValue,
                  }),
                  pattern: {
                    value: namesWithSpacesAndSpecialChars,
                    message: t('errors.pattern', {
                      field: firstNameValue,
                    }),
                  },
                }}
                aria-label={t('contact.enter-message', {
                  value: firstNameValue,
                })}
              />
            </Form.InputWrapper>
            <Form.InputWrapper className="col-span-2 md:col-span-1">
              <Form.Input
                groupClassName="flex flex-col"
                placeholder={t('contact.placeholder-last-name')}
                data-testid="contactUsPageLastNameField"
                label={t('contact.label', {
                  value: lastNameValue,
                })}
                name="lastName"
                rules={{
                  required: t('contact.enter', {
                    value: lastNameValue,
                  }),
                  pattern: {
                    value: namesWithSpacesAndSpecialChars,
                    message: t('errors.pattern', {
                      field: lastNameValue,
                    }),
                  },
                }}
                aria-label={t('contact.enter-message', {
                  value: lastNameValue,
                })}
              />
            </Form.InputWrapper>
            <Form.InputWrapper className="col-span-2">
              <Form.Input
                groupClassName="flex flex-col"
                placeholder={t('contact.placeholder-email')}
                data-testid="contactUsPageEmailField"
                label={t('contact.label', {
                  value: emailValue,
                })}
                name="email"
                type="email"
                rules={{
                  required: t('contact.enter', {
                    value: emailValue,
                  }),
                  pattern: {
                    value: validEmailRegEx,
                    message: t('contact.email-error-message'),
                  },
                }}
                aria-label={t('contact.enter-message', {
                  value: emailValue,
                })}
              />
            </Form.InputWrapper>
            <Form.InputWrapper className="col-span-2">
              <Form.TextArea
                groupClassName="flex flex-col"
                placeholder={t('contact.placeholder-message')}
                data-testid="contactUsPageMessageField"
                className="min-h-20"
                label={t('contact.label', {
                  value: messageValue,
                })}
                name="message"
                rules={{
                  required: t('contact.enter', {
                    value: messageValue,
                  }),
                }}
                aria-label={t('contact.enter-message', {
                  value: messageValue,
                })}
              />
            </Form.InputWrapper>
          </Form>
          <Button.Primary
            size="lg"
            type="submit"
            form="contact-form"
            data-testid="contactUsPageSubmitFormButton"
            loading={isLoading}
          >
            {t('common.submit')}
          </Button.Primary>
          <div className="fixed z-10">
            <ReCAPTCHA
              ref={reCaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              size="invisible"
            />
          </div>
          {mailState && (
            <Alert res={mailState} destroy={() => setMailState()} />
          )}
        </div>
        <div className="hidden flex-1 lg:block">
          <img
            src="/images/empty-gallery.png"
            alt=""
            data-testid="contactUsPageHeroImage"
          />
        </div>
      </div>
    </ContentContainer>
  );
}

export default Contact;
