import { useQuery } from 'react-query';
import { useLibraryApi } from 'library/libraryApiContext';

const JOURNEYS_KEYS = {
  all: () => ['journeys'],
  tableOfContents: () => [...JOURNEYS_KEYS.all(), 'table-of-contents'],
  byId: (id) => [...JOURNEYS_KEYS.all(), 'journey', id],
  collection: (id) => [...JOURNEYS_KEYS.all(), 'collection', id],
};

export function useJourneyTableOfContentsMapper(journey = {}) {
  return {
    id: journey.id,
    key: journey.id,
    type: 'journey',
    children: journey.collections
      ?.filter((x) => x)
      .map((collection) => ({
        id: collection.id,
        key: collection.id,
        label: collection.name,
        type: collection.type,
        journeyId: journey.id,
        children:
          collection?.subcollections?.length > 1
            ? collection.subcollections
                ?.filter((x) => x)
                .map((subCollection) => ({
                  key: subCollection?.id,
                  id: subCollection?.id,
                  label: subCollection?.name,
                  journeyId: journey.id,
                  type: 'subcollection',
                }))
            : [],
      })),
  };
}

export function useGetJourneys(id, options) {
  const libraryAPI = useLibraryApi();
  return useQuery(
    JOURNEYS_KEYS.byId(id),
    async () => libraryAPI.getJourneyById(id),
    options
  );
}

export function useJourneyCollection(id, options) {
  const libraryAPI = useLibraryApi();
  return useQuery(
    JOURNEYS_KEYS.collection(id),
    async () =>
      libraryAPI.getJourneyCollectionById(id).then((data) => data?.[id]),
    options
  );
}
