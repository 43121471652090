import { filter, head, identity, props } from 'ramda';
import { useQuery } from 'react-query';

import { useLibraryApi } from 'library/libraryApiContext';

const getQueryKey = (query) => ['library', 'storyblock', query];

export function useStoryBlockQuery(query, options) {
  const api = useLibraryApi();
  const isMulti = Array.isArray(query);
  const ids = isMulti ? query : [query];

  return useQuery(
    getQueryKey(ids),
    () => api.getStoryBlocksById(ids).then(props(ids)).then(filter(Boolean)),
    {
      enabled: ids?.filter(Boolean).length > 0,
      select: isMulti ? identity : head,
      ...options,
    }
  );
}

export function useDefaultStoryBlock(type, options) {
  const api = useLibraryApi();
  const { data } = useQuery(
    getQueryKey(type),
    () => api.getDefaultStoryblock(type),
    options
  );
  return data && head(data?.data);
}
