import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';

import '@szhsin/react-menu/dist/index.css';
import './DropMenu.css';

function DropMenu({ menuButton, children, ...rest }) {
  return (
    <Menu
      menuButton={menuButton}
      arrow={false}
      boundingBoxPadding="1rem"
      direction="left"
      viewScroll="close"
      initialMounted={false}
      unmountOnClose
      menuClassName="smd-drop-menu"
      {...rest}
    >
      {children}
    </Menu>
  );
}

function DropMenuItem(props) {
  return <MenuItem className="smd-drop-menu-item" {...props} />;
}

DropMenu.Button = MenuButton;
DropMenu.Item = DropMenuItem;

export default DropMenu;
