import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from 'components/icons';
import { getAssetUrl } from 'utils';
import { Image } from 'components/media';
import { SliderCollectionNavigation } from 'components/navigation';
import OverlappingElementsGrid from 'components/OverlappingElementsGrid';

function getImageAsset(asset, index) {
  return {
    id: index,
    src: getAssetUrl(asset.url),
    name: asset.name,
    type: asset.type,
  };
}

function Slideshow({ config, className, imageMaxHeight = 600, style = {} }) {
  const { t } = useTranslation();
  const assets = useMemo(() => {
    let isArrayWithArrays =
      config.assets[config.assets.length - 1] &&
      config.assets[config.assets.length - 1].length > 0;
    if (isArrayWithArrays) {
      return config.assets.map((x) => x.map(getImageAsset)).flat();
    }
    return [config.assets.map(getImageAsset)].flat();
  }, [config]);
  const [currentAssetIndex, setCurrentAssetIndex] = useState(0);

  const currentAsset = assets[currentAssetIndex];

  return (
    <div className={className} style={style}>
      <div className="relative px-6 pt-6">
        {assets.map((asset, index) => {
          const { src, name } = asset;
          const className =
            asset === currentAsset
              ? 'w-full h-full object-contain'
              : 'invisible absolute w-0 h-0';
          return (
            <Image
              key={index}
              className={className}
              src={src}
              alt={name && 'Slideshow asset'}
              style={{ maxHeight: imageMaxHeight }}
            />
          );
        })}

        {currentAssetIndex > 0 && (
          <button
            className="absolute top-0 bottom-0 left-0 hidden w-6 p-0.5 focus:outline-none lg:block"
            onClick={() => {
              setCurrentAssetIndex(currentAssetIndex - 1);
            }}
            aria-label={t('labels.carousel.prev')}
          >
            <ChevronLeft strokeWidth="2" />
          </button>
        )}

        {currentAssetIndex < assets.length - 1 && (
          <button
            className="absolute top-0 bottom-0 right-0 hidden w-6 p-0.5  focus:outline-none lg:block"
            onClick={() => {
              setCurrentAssetIndex(currentAssetIndex + 1);
            }}
            aria-label={t('labels.carousel.next')}
          >
            <ChevronRight strokeWidth="2" />
          </button>
        )}
      </div>
      <OverlappingElementsGrid>
        {assets.map((asset, index) => (
          <div
            key={`element-${index}`}
            className={`hidden w-full space-y-3 px-6 pt-3 text-center text-base lg:block ${
              asset === currentAsset ? 'visible' : 'invisible'
            }`}
          >
            <div>{asset?.name}</div>
          </div>
        ))}
      </OverlappingElementsGrid>
      <div className=" px-6 pt-3">
        <SliderCollectionNavigation
          items={assets}
          currentItem={currentAsset}
          onChange={(item, index) => setCurrentAssetIndex(index)}
        />
      </div>
    </div>
  );
}

export default Slideshow;
