import { Link, useHistory } from 'react-router-dom';
import { useAuth } from 'Auth';
import { ContentContainer, CTA } from 'components';
import { Button } from 'components/forms';
import { getAssetUrl } from 'utils';
import { useMedia, usePublicResource } from 'hooks';

import { useUserSubscriptionStatus } from 'my-phr/hooks';
import { PHR_ROUTES } from 'my-phr/const';

function StoryMDHealthSection() {
  const isMediumScreen = useMedia(useMedia.MEDIUM);
  const { data } = usePublicResource('/home.json');
  const healthData = data?.['storymd-health']?.[0];
  const { title, text, featuredAsset } = healthData ?? {};

  const history = useHistory();

  const { authenticated } = useAuth();
  const { isSubscriptionActive } = useUserSubscriptionStatus();

  const primaryButtonText = healthData['cta-text'];
  const secondaryButtonText = healthData['cta-internal-text'];

  const ButtonComponent = authenticated ? Button : CTA;
  const imageComponent = (
    <img
      alt="test"
      src={getAssetUrl(featuredAsset?.url)}
      className="w-2/3 object-cover object-center md:w-full"
    />
  );

  if (authenticated && isSubscriptionActive) return null;

  return (
    <section className="px-smd-base pb-smd-xl pt-smd-lg antialiased md:min-h-[92vh] md:px-smd-xxl md:pb-smd-xxl lg:min-h-[85vh]">
      <ContentContainer className="flex h-full">
        <div className="flex flex-grow flex-col items-center md:flex-row md:space-x-12">
          {!isMediumScreen && (
            <div className="flex justify-center">{imageComponent}</div>
          )}
          <div className="py-8 text-center md:text-left">
            <h1 className="pb-8 text-smd-h2.5 font-semibold md:text-smd-h1">
              {title}
            </h1>
            <p
              className="pb-8 text-smd-base md:text-smd-h4"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <div className="flex flex-col items-center justify-center space-x-0 space-y-2 md:items-start xl:w-full xl:flex-row xl:justify-start xl:space-x-2 xl:space-y-0 ">
              <ButtonComponent
                color="primary"
                ctaProps={{ ctaAction: 'login' }}
                onClick={() => history.push(PHR_ROUTES.SETUP_PROFILE)}
              >
                {primaryButtonText}
              </ButtonComponent>

              <Button color="tertiary" className="ml-4">
                <Link to="/health" tabIndex={-1}>
                  {secondaryButtonText}
                </Link>
              </Button>
            </div>
          </div>
          {isMediumScreen && (
            <div className="relative flex w-1/2 shrink-0 items-center xl:w-2/5">
              {imageComponent}
            </div>
          )}
        </div>
      </ContentContainer>
    </section>
  );
}

export default StoryMDHealthSection;
