import { __, defaultTo, modifyPath } from 'ramda';
import { getDistributionConfig } from 'distribution';

export function getDistributionLogos(journalKey) {
  return getDistributionConfig(['journals', 'logos', journalKey]);
}

export function applyDistributionConfig(journal) {
  const distributionLogos = getDistributionLogos(journal?.journalKey);

  return modifyPath(
    ['preamble', 'logos'],
    defaultTo(__, distributionLogos),
    journal
  );
}
