import React, { forwardRef } from 'react';

const base = [
  'form-checkbox',
  'text-smd-accent',
  'border border-smd',
  'smd-focus-visible-primary',
];

export default forwardRef(function Checkbox(
  { className, type, ...props },
  ref
) {
  return (
    <input
      type="checkbox"
      ref={ref}
      className={base.concat(className).join(' ')}
      {...props}
    />
  );
});
