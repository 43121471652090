import { forwardRef } from 'react';
import { path } from 'ramda';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isAfter } from 'date-fns';
import { isFunction } from 'utils';
import { Calendar } from 'components/icons';
import { useMedia } from 'hooks';
import { Label } from '..';
import { registerFormElement } from './utils';
import FormController from './FormController';

const base = [
  'border-smd-gray-light',
  'p-2',
  'leading-normal',
  'w-full',
  'placeholder:text-smd-tertiary',
  'smd-input-focus-primary',
];

export const CustomDateInput = forwardRef(
  ({ value, onClick, placeholder }, ref) => (
    <div className="relative">
      <input
        value={value}
        className="smd-input-focus-primary h-11 w-[11.5rem] border-1 border-smd-gray-light bg-white px-2 md:w-64"
        onClick={onClick}
        placeholder={placeholder}
        ref={ref}
      />
      <Calendar className="pointer-events-none absolute left-[9.5rem] top-2/4 h-5 w-5 -translate-y-2/4 transform text-smd-accent md:left-56" />
    </div>
  )
);

function FormInputDate(props) {
  const {
    label,
    labelProps,
    register,
    name,
    id = name,
    rules,
    className,
    dateFormat,
    placeholder,
    errors,
    showYearDropdown = false,
    showMonthDropdown = false,
    defaultValue,
    customDateParser,
    min,
    max,
    ...rest
  } = props;

  const _name = [...name.split('.')];
  const error = path(_name, errors);

  const border = error
    ? 'border-red-500 focus:border-red-500 focus:!ring-offset-red-500'
    : 'border-smd focus:border-smd-accent';

  const classes = base.concat(border, className).join(' ');

  const handleKeyDownEvent = (event) => {
    if (event.key !== 'Backspace' && event.key !== 'Delete') {
      event.preventDefault();
    }
  };

  const filterTime = (time) => (min ? isAfter(time, min) : true);

  const isMedium = useMedia(useMedia.MEDIUM);

  return (
    <FormController
      {...rest}
      id={id}
      errors={errors}
      rules={rules}
      withError={error}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, onBlur, ref } }) => {
        const pickerValue = isFunction(customDateParser)
          ? customDateParser(value)
          : value;

        return (
          <>
            {label ? (
              <Label htmlFor={id} {...labelProps}>
                {label}
              </Label>
            ) : (
              <></>
            )}
            <ReactDatePicker
              ref={(element) => {
                element && ref(element.input);
              }}
              showYearDropdown={showYearDropdown}
              showMonthDropdown={showMonthDropdown}
              wrapperClassName="!w-fit"
              dropdownMode="select"
              showPopperArrow={false}
              selected={pickerValue}
              onChange={onChange}
              onBlur={onBlur}
              timeIntervals={60}
              withPortal={!isMedium}
              dateFormat={dateFormat}
              className={classes}
              placeholderText={placeholder}
              onKeyDown={handleKeyDownEvent}
              minDate={min}
              maxDate={max}
              filterTime={filterTime}
              popperPlacement="auto"
              customInput={<CustomDateInput />}
            />
          </>
        );
      }}
    />
  );
}

registerFormElement(FormInputDate);

export default FormInputDate;
