import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  endOfMonth,
  format,
  parseISO,
  startOfMonth,
  subMonths,
} from 'date-fns';
import classnames from 'classnames';
import { dissoc, isEmpty, isNil, propOr } from 'ramda';

import {
  ViewerLayout,
  ViewerPageNavigation,
  ViewerPageSkeleton,
  ViewerTopbar,
} from 'components/viewer';
import NotFound from 'NotFound';

// TODO: move to /library
import { useLibraryPopOver } from 'journals/blocks/output/HtmlBlock/LibraryTopic';
import ParentContext from 'journals/viewer/ParentContext';

import {
  ExpandableSection,
  LibraryReferences,
  ExpandableSectionList,
  Skeleton,
} from 'components';
import { FocalPointBackground } from 'components/media';
import { useAuth } from 'Auth';
import { useIsFeatureEnabled, useMedia } from 'hooks';
import { useParseSearch, useSearch } from 'search/hooks';
import { Button } from 'components/forms';
import { AddEmail, ChevronRight, Share } from 'components/icons';
import { Carousel, VirtualCarousel } from 'components/Carousel';
import { SEOMeta } from 'components/SEO';
import { NewsletterModal } from 'components/Newsletter';
import { AddToCollectionModal, SmartJournalCard } from 'journals/components';
import { AddToCollection } from 'journals/components/actions';
import { getJournalSlugPath, normalizeAssetUrl } from 'journals/utils';
import { slugifyObject } from 'utils/slug';

import { parseJournals } from 'landing/utils';
import { useLibraryQuery, useStoryLastLocation } from '../hooks';
import { LibraryArticle } from '../components/article';

function LibraryStoryViewerPage({ hashId, onShare, onClose }) {
  const { t } = useTranslation();
  const { authenticated } = useAuth();
  const LibraryCardPopOverProvider = useLibraryPopOver();

  const showSubscriptionButton = useIsFeatureEnabled(
    useIsFeatureEnabled.NEWSLETTER
  );

  const [actionJournal, setActionJournal] = useState();

  const [newsLetterModal, setNewsletterModal] = useState(false);

  const {
    data: story = {},
    isFetching: isFetchingStory,
    isFetched,
  } = useLibraryQuery(hashId);

  const journals = Boolean(story.journals?.length)
    ? parseJournals(story.journals)
    : [];
  const isMedium = useMedia(useMedia.MEDIUM);

  const breakpoints = {
    [useMedia.xl]: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    [useMedia.md]: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    [useMedia.sm]: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
  };

  if (isFetched && isEmpty(story)) {
    return <NotFound buttonLabel={t('common.back-to-homepage')} />;
  }

  const noNameStory = dissoc('name', story);
  const datetime = parseISO(
    propOr(new Date().toISOString(), 'datetime')(story)
  );

  const parsedDate = format(
    datetime,
    isMedium ? 'EEEE, MMMM d, y' : 'MMMM d, y'
  );

  const relatedLink = story.links?.explore;

  const prev = story.events?.prev && {
    ...story.events?.prev,
    label: story.events?.prev?.name,
  };

  const next = story.events?.next && {
    ...story.events?.next,
    label: story.events?.next?.name,
  };

  const showNavigation = !Boolean(isNil(prev) && isNil(next));

  const hasRelatedLink = Boolean(relatedLink?.url);

  const Element = hasRelatedLink ? Link : 'p';
  const ElementProps = hasRelatedLink ? { to: relatedLink?.url } : {};

  return (
    <>
      <StoryOfTheDayMeta story={story} />
      <ViewerLayout
        topbar={
          <ViewerTopbar
            title={
              <h1 className="flex cursor-auto flex-row space-x-2 lg:items-center lg:space-x-4">
                <span className="hidden truncate md:block lg:max-w-[60ch] xl:max-w-[80ch]">
                  {t('library.story-of-the-day')}:
                </span>
                {!isEmpty(story) && (
                  <span className="font-normal">{parsedDate}</span>
                )}
              </h1>
            }
            onCloseClick={() => onClose()}
            logos={[ViewerTopbar.smdLogo]}
          />
        }
        main={
          <div className="px-4 lg:flex lg:justify-center lg:px-0">
            <ParentContext.Provider className="relative m-4 mx-auto w-full max-w-4xl space-y-6 lg:mx-8">
              <LibraryCardPopOverProvider>
                {isFetchingStory ? (
                  <ViewerPageSkeleton />
                ) : (
                  <article>
                    <header className="mb-6 flex flex-col items-start space-y-4">
                      <h1
                        className="grow text-smd-h2 font-semibold"
                        dangerouslySetInnerHTML={{ __html: story.name }}
                      />
                      <div className="flex space-x-4">
                        {showSubscriptionButton && (
                          <Button.Primary
                            onClick={() => setNewsletterModal(true)}
                          >
                            <div className="mx-auto flex items-center space-x-2">
                              <AddEmail className="h-4 w-4 fill-current" />
                              <span>{t('common.subscribe')}</span>
                            </div>
                          </Button.Primary>
                        )}
                        {onShare && (
                          <Button.Tertiary
                            className="shrink-0 font-semibold"
                            onClick={onShare}
                          >
                            <Share className="h-6 w-6" />
                            <span>{t('common.share')}</span>
                          </Button.Tertiary>
                        )}
                      </div>
                    </header>
                    <LibraryArticle withLinks article={noNameStory} />
                  </article>
                )}
                {story.references && (
                  <ExpandableSectionList
                    className="px-0 py-3 md:py-3"
                    initialOpenIndex={-1}
                  >
                    <ExpandableSection title={t('library.topic.references')}>
                      <LibraryReferences references={story.references} />
                    </ExpandableSection>
                  </ExpandableSectionList>
                )}

                {Boolean(relatedLink?.name) && (
                  <Element
                    className={classnames(
                      'my-2 inline-flex max-w-full select-none items-center space-x-2 text-smd-h2.5 font-semibold',
                      hasRelatedLink &&
                        'smd-focus-visible-primary rounded-sm hover:underline focus-visible:underline',
                      hasRelatedLink ? 'cursor-pointer' : 'cursor-default'
                    )}
                    {...ElementProps}
                  >
                    <span
                      className="line-clamp-2 lg:line-clamp-1"
                      title={relatedLink.name}
                    >
                      {relatedLink.name}
                      {hasRelatedLink && (
                        <ChevronRight className="inline h-6 w-6 flex-none stroke-2 lg:hidden" />
                      )}
                    </span>
                    {hasRelatedLink && (
                      <ChevronRight className="hidden h-6 w-6 flex-none stroke-2 lg:block" />
                    )}
                  </Element>
                )}

                <div className="grid w-full">
                  <div className="overflow-hidden lg:-mx-10 lg:px-14">
                    {Boolean(journals?.length) && (
                      <VirtualCarousel
                        withNavigation
                        carouselProps={{
                          breakpoints,
                        }}
                        slides={journals.map((journal) => {
                          return (
                            <Carousel.Slide key={journal.id}>
                              <div className="smd-transform-duration">
                                <SmartJournalCard
                                  actions={[
                                    {
                                      onClick: () => setActionJournal(journal),
                                      element: <AddToCollection />,
                                    },
                                  ]}
                                  journal={journal}
                                  to={getJournalSlugPath(journal)}
                                />
                              </div>
                            </Carousel.Slide>
                          );
                        })}
                      />
                    )}
                  </div>
                </div>

                {showNavigation && (
                  <ViewerPageNavigation
                    prev={prev}
                    next={next}
                    prevLink={prev?.link}
                    nextLink={next?.link}
                  />
                )}
              </LibraryCardPopOverProvider>
            </ParentContext.Provider>

            <div className="mb-20 w-full space-y-6 pt-4 lg:w-60 lg:shrink-0 lg:pr-8 xl:w-72 xl:pl-12">
              <RecentStories currentStoryId={story.id} />
              <MoreStoriesList />
            </div>

            {authenticated && (
              <AddToCollectionModal
                journal={actionJournal}
                onClose={() => setActionJournal()}
              />
            )}
          </div>
        }
      />
      <NewsletterModal
        isOpen={newsLetterModal}
        onRequestClose={() => {
          setNewsletterModal(false);
        }}
      />
    </>
  );
}

function RecentStories({ currentStoryId }) {
  const { t } = useTranslation();

  const { searchResults, isFetching, isFetched } = useSearch({
    type: 'event',
    limit: 5,
  });
  const parsedStories = useParseSearch(searchResults, { type: 'event' });
  const isLoading = isFetching && !isFetched;

  return (
    <>
      <p className="text-xl font-bold text-smd-gray-darker">
        {t('library.recent-stories')}
      </p>
      <div
        className="grid gap-4"
        style={{
          gridTemplateColumns: `repeat(auto-fill, minmax(min(15rem, 100%), 1fr))`,
        }}
      >
        {isLoading ? (
          <RecentStoriesSkeleton />
        ) : (
          <RecentStoriesGrid
            stories={parsedStories}
            currentStoryId={currentStoryId}
          />
        )}
      </div>
    </>
  );
}

function RecentStoriesSkeleton() {
  return [...Array(3).keys()].map((x) => {
    return (
      <div className="flex flex-col shadow-md" key={`recent_story_loader_${x}`}>
        <Skeleton className="h-36 w-full" />
        <div className="p-2">
          <Skeleton.Text trimLastLine lines={2} />
        </div>
      </div>
    );
  });
}

function RecentStoriesGrid({ stories, currentStoryId }) {
  const { t } = useTranslation();
  const { pushStoryLastLocation } = useStoryLastLocation();

  return stories.map((story) => {
    const storyUrl = `/story/${encodeURIComponent(
      slugifyObject(story.id, story.name)
    )}`;
    const thumbnail = normalizeAssetUrl(story?.featuredAsset?.thumbnail);
    const focalPoint = story?.featuredAsset?.focalPoint;

    return (
      <Link
        to={storyUrl}
        key={`recent_story_card_${story.id}`}
        onClick={() => {
          if (currentStoryId !== story.id) {
            pushStoryLastLocation();
          }
        }}
        className="smd-focus-visible-primary flex flex-col rounded-sm shadow-md"
      >
        <FocalPointBackground
          className="h-36 w-full shrink-0"
          focalPoint={focalPoint}
          url={thumbnail}
        />
        <div className="flex h-full flex-col space-y-2 p-2">
          <p className="grow text-sm font-semibold text-smd-gray-darker line-clamp-2">
            {story.title}
          </p>
          <span className="inline-block text-sm text-smd-gray-dark lg:text-xs">
            {t('library.story-of-the-day')}:{' '}
            {format(new Date(story.datetime), 'MMMM d, yyyy')}
          </span>
        </div>
      </Link>
    );
  });
}

function MoreStoriesList() {
  const { t } = useTranslation();

  function createStoriesLink(state) {
    return { pathname: '/explore', search: '?tab=event', state };
  }

  return (
    <div>
      <p className="text-xl font-bold text-smd-gray-darker">
        {t('library.more-stories')}
      </p>
      <ul className="space-y-4 py-4">
        {[...Array(6).keys()].map((decrement) => {
          const date = new Date(subMonths(new Date(), decrement));
          return (
            <li key={`moreStoriesFrom${decrement}MonthsBack`}>
              <Link
                to={createStoriesLink({
                  storyTimeFiltersState: {
                    value: 'custom',
                    startDate: startOfMonth(date),
                    endDate: endOfMonth(date),
                  },
                })}
                className="smd-focus-visible-primary rounded-sm text-smd-base text-smd-accent"
              >
                {format(date, 'MMMM, yyyy')}
              </Link>
            </li>
          );
        })}
        <li>
          <Link
            to={createStoriesLink()}
            className="smd-focus-visible-primary inline-flex items-center space-x-1 rounded-sm text-base font-bold text-smd-accent"
          >
            <span>{t('library.view-all')}</span>
            <ChevronRight className="h-5 w-5 stroke-2" />
          </Link>
        </li>
      </ul>
    </div>
  );
}

function StoryOfTheDayMeta({ story }) {
  return (
    <SEOMeta
      values={{
        title: story.name,
        description: story.description,
        image: normalizeAssetUrl(story.featuredAsset?.url),
      }}
    />
  );
}

export default LibraryStoryViewerPage;
